import request from '../utils/request';
//import request from '../utils/requestTests/requestTestOrganization';

export function fetchOrganizations() {
  return request({
    url: '/Organizations',
    method: 'get',
  });
}

export function fetchOrganization(id) {
  return request({
    url: `/Organizations/${id}`,
    method: 'get',
  });
}