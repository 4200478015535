import React from "react";
import Header from "components/Headers/Header.jsx";
import 'react-leaflet-fullscreen/dist/styles.css'
import { getAllTypeTerritorializations, postTerritorialization } from "../../services/api/territorialization";
import Select from "react-select";


class importTerritorialization extends React.Component {
    state = {
        typeLayerOptions: [],
        selectedLayer:"",
        GeoJson: "",
    }

    componentDidMount(){
        const reqTypeLayers = getAllTypeTerritorializations();
        reqTypeLayers.then(response => {
            const typeLayerOptions = response.data.map(tpl => {return {label: tpl.name, value: tpl.id}});
            this.setState({typeLayerOptions});
        })

    }

    SaveData = async () => {
        const { selectedLayer, GeoJson } = this.state;
        if (GeoJson !== "" && selectedLayer !== "") {
            const jsonObject = JSON.parse(GeoJson);
            if (jsonObject.features !== undefined && Array.isArray(jsonObject.features)) {
                const batchSize = 250; // Tamanho do lote
                const requests = []; // Armazena as promessas
    
                for (let i = 0; i < jsonObject.features.length; i++) {
                    const geo = jsonObject.features[i];
                    const sendObject = {
                        GeoJson: this.layerToGeoJson(geo, "Polygon"),
                        nome: geo.properties.NOME,
                        camada: selectedLayer.value
                    };
    
                    // Adiciona a promessa da requisição ao array
                    requests.push(postTerritorialization(sendObject));
    
                    // Se atingimos o tamanho do lote ou chegamos ao final, aguardamos as requisições terminarem
                    if (requests.length === batchSize || i === jsonObject.features.length - 1) {
                        await Promise.all(requests); // Aguarda todas as requisições do lote finalizarem
                        requests.length = 0; // Limpa o array para o próximo lote
                    }
                }

                alert("Importação concluída");
            }
        }
    };
    
 
    layerToGeoJson = (layer, type) => {
        // estruturando o geojson
        var geojson = {};
        geojson['type'] = 'FeatureCollection';

        var geometry = {};
       
            geometry['type'] = type;

            // Pega as coordenadas do Layer desenhado
            var coordinates = [];
            var geo = layer;

            if(geo.geometry.type === "Polygon"){
                var latlngs = geo.geometry.coordinates[0];
            }else if(geo.geometry.type === "MultiPolygon"){
                var latlngs = geo.geometry.coordinates[0][0];
            }

            for (var i = 0; i < latlngs.length; i++) {
                coordinates.push([latlngs[i][0], latlngs[i][1]]);
            }

                // O Geojson obrigatoriamente precisa terminar com o mesmo ponto de partida
            var first_coord = coordinates[0];
            coordinates[coordinates.length] = first_coord;
            geometry['coordinates'] = [coordinates];
        

        //montando a feature
        geojson['features'] = [];

        var Feature = {
            "type": "Feature",
            "geometry": geometry,
            "properties":null
        };

        geojson['features'][0] = Feature;

        return JSON.stringify(geojson);
    }
    


    render() {
        return (
            <>
                <Header />
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <Select 
                    options={this.state.typeLayerOptions}
                    placeholder={"Tipo de Camada"}
                    onChange={(e) => this.setState({selectedLayer: e})}
                    value={this.state.selectedLayer}
                />
                <textarea onChange={(e) => this.setState({GeoJson: e.target.value})} type="text" className="form-control" placeholder="GeoJson" />
                <button className="float-right btn btn-success" onClick={this.SaveData}>Salvar</button>
            </>
        );
    };
}

export default importTerritorialization;