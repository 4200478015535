import React from "react";
import { Select } from "../Wrappers/SelectAll";
import { selectComponentStyles } from "../../services/utils/globalFunctions";
import getWeeks from "../../services/utils/epidemiologicalWeek";

const YearsFilter = ({ filterName, handleFiltersChange, value, ...props }) => {
  const [yearsOptionsList, setYearsOptionsList] = React.useState([]);

  React.useEffect(() => {
    const epidemiologicalWeeks = getWeeks();

    const yearsList = epidemiologicalWeeks
      .map((week) => ({
        label: week.year,
        value: +week.year,
      }))
      .sort((a, b) => b.value - a.value);

    setYearsOptionsList(yearsList);
  }, []);

  return (
    <div>
      {/* <span className="h4 text-white">Ano</span> */}
      <Select
        styles={selectComponentStyles}
        placeholder={"Selecione"}
        isClearable={true}
        value={value}
        options={yearsOptionsList}
        onChange={(e) =>
          handleFiltersChange(
            filterName,
            !e
              ? {
                  label: "Selecione",
                  value: "",
                }
              : e
          )
        }
        {...props}
      />
    </div>
  );
};

export default YearsFilter;