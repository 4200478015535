import React from "react";
import { Select } from "../Wrappers/SelectAll";
import { selectComponentStyles } from "../../services/utils/globalFunctions";

const TrapTypesFilter = ({
  filterName,
  handleFiltersChange,
  options,
  value,
  ...props
}) => {
  return (
    <div>
      <Select
        styles={selectComponentStyles}
        placeholder={"Selecione"}
        isClearable={true}
        value={value}
        options={options}
        onChange={(e) =>
          handleFiltersChange(
            filterName,
            !e
              ? {
                  label: "Selecione",
                  value: "",
                }
              : e
          )
        }
        {...props}
      />
    </div>
  );
};

export default TrapTypesFilter;