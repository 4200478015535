import moment from "moment";
import React from "react";

import { createContext } from "use-context-selector";
import {
  trapsColorStatusesOptionsList,
  trapsStatusesOptionsList,
} from "../../../../services/utils/globalFunctions";

const TrapsPageContext = createContext(null);

const useTrapsPageContext = () => {
  const context = React.useContext(TrapsPageContext);

  if (!context)
    throw new Error("useContext de TrapsPage deve estar dentro do Provider");

  return context;
};

const DEFAULT_FILTERS = {
  beginDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
  colorStatuses: trapsColorStatusesOptionsList,
  demand: {
    label: "Todas",
    value: "",
  },
  employees: [],
  endDate: moment().format("YYYY-MM-DD"),
  statuses: trapsStatusesOptionsList,
  team: {
    label: "Todos",
    members: [],
    value: "",
  },
  territorializations: [],
  trapType: {
    label: "Selecione",
    trapTypeName: "",
    value: "",
  },
  typeLayers: [],
};

const TrapsPageContextProvider = ({ children }) => {
  const [isEditTrapModalOpen, setIsEditTrapModalOpen] = React.useState(false);
  const [selectedTrapNumber, setSelectedTrapNumber] = React.useState("");
  const [appliedFilters, setAppliedFilters] = React.useState(DEFAULT_FILTERS);
  const [isTrapsListLoading, setIsTrapsListLoading] = React.useState(false);
  const [trapsSituationsByColors, setTrapsSituationsByColors] = React.useState({
    green: 0,
    yellow: 0,
    red: 0,
    white: 0,
    range: null,
    trapTypeName: "",
  });

  const toggleEditTrapModal = React.useCallback(() => {
    setIsEditTrapModalOpen((prevState) => {
      if (prevState) setSelectedTrapNumber("");

      return !prevState;
    });
  }, []);

  return (
    <TrapsPageContext.Provider
      value={{
        appliedFilters,
        isEditTrapModalOpen,
        isTrapsListLoading,
        selectedTrapNumber,
        trapsSituationsByColors,
        setAppliedFilters,
        setIsTrapsListLoading,
        setSelectedTrapNumber,
        toggleEditTrapModal,
        setTrapsSituationsByColors,
      }}
    >
      {children}
    </TrapsPageContext.Provider>
  );
};

export { TrapsPageContextProvider, useTrapsPageContext, TrapsPageContext };