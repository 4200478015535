import { GOOGLE_MAPS_API_KEY } from "../../../constants/WebFormConstants";
import { fetchOrganization } from "../../api/Organization";
import { getOrganization } from "../../api/Users";
import { getUserData } from "../auth";

export const checkIfRequiredFieldsAreEmpty = (headerInputs, tableRows, organizationId) => {
  const EMPTY_FIELD = "";
  let errorMessage = "";

  if(organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8"){
    if (headerInputs.uvisName.value === EMPTY_FIELD) return "Informe o nome da UVIS";
    if (headerInputs.administrativeDistrict.value === EMPTY_FIELD)
      return "Informe o Distrito Administrativo";
  }

  if (headerInputs.teamsAndEmployees.length === 0)
    return "Informe os funcionários que participaram da atividade";
  if (headerInputs.activityDate === EMPTY_FIELD)
    return "Informe a data da atividade";

  for (let i = 0; i < tableRows.length; i++) {
    const { address, addressNumber, propertyType, trapNumber, visitStatus } =
      tableRows[i];
    if (address === EMPTY_FIELD) {
      errorMessage = `Informe o LOGRADOURO da residência na linha ${i + 1}`;
      break;
    }
    if (addressNumber === EMPTY_FIELD) {
      errorMessage = `Informe o NÚMERO da residência na linha ${i + 1}`;
      break;
    }
    if (propertyType === EMPTY_FIELD) {
      errorMessage = `Informe o TIPO DE IMÓVEL da residência na linha ${i + 1}`;
      break;
    }
    if (trapNumber === EMPTY_FIELD) {
      errorMessage = `Informe o NÚMERO da armadilha na linha ${i + 1}`;
      break;
    }
    if (visitStatus === EMPTY_FIELD) {
      errorMessage = `Informe o STATUS da visita na linha ${i + 1}`;
      break;
    }
  }

  return errorMessage !== "" ? errorMessage : false;
};

export const getAddressInformations = async (address, addressNumber) => {
  const { data } = await fetchOrganization(getUserData("organizationId"));
  const cityAndState = `${data.tradeName} - ${data.uf}, - Brasil`;
  const fullAddress = `${address}, ${addressNumber}, ${cityAndState}`;

  const addressApiRequest = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAPS_API_KEY}&address=${fullAddress}`
  );
  const requestResponseInJson = await addressApiRequest.json();

  if (!requestResponseInJson) return false;

  return requestResponseInJson.results[0];
};