import { useContextSelector } from "use-context-selector";
import { TrapsPageContext } from "../Contexts/trapsPageContext";

const useLoading = () => {
  const isTrapsListLoading = useContextSelector(
    TrapsPageContext,
    (context) => context.isTrapsListLoading
  );
  const setIsTrapsListLoading = useContextSelector(
    TrapsPageContext,
    (context) => context.setIsTrapsListLoading
  );

  return {
    isTrapsListLoading,
    setIsTrapsListLoading,
  };
};

export default useLoading;
