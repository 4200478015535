import { getUserData } from "../auth";

export const getDemandIdByOrganizationId = {
  "24d125ef-15e6-4bd9-9111-c0b3d50a8009":
    "6cc0408e-1b98-4359-aee4-ff603d3d71f4",
  "37601ec4-d2ef-4f86-aeac-fd261e8fded4":
    "385bd303-3734-4211-8b0c-abf971ac47ac",
  "559a16ab-5da8-41ba-96b8-6e9f55feddd8":
    "e7e35b71-37c8-48ad-b805-ea9253ffe3f6",
  "42a4ea68-1e69-428c-9498-e4727d30dd20":
    "0095d03e-d178-4bf2-9e7e-866ff65713fd",
  "5c9146b6-76dc-4203-ae95-a5099b667b8b":
    "96899344-d39d-4fe9-892d-bf21a10c11f1"
};

const getLarvaeEstimates = {
  999: "0",
  9: "1 a 20",
  10: "21 a 50",
  11: "51 a 100",
  12: "Mais de 100",
};

const getPupaEstimates = {
  999: "0",
  5: "1 a 10",
  6: "11 a 20",
  7: "21 a 50",
  8: "Mais de 50",
};

const getMosquitoEstimates = {
  999: "0",
  1: "1 a 5",
  2: "6 a 10",
  3: "11 a 20",
  4: "Mais de 20",
};

const checkIfSpecificMaintenanceOcurred = (
  maintenanceName,
  trapMaintenances
) => {
  const result = trapMaintenances.find(
    (maintenance) => maintenance.value === maintenanceName
  );

  return result ? true : false;
};

export const getEntomologicVigilanceObjectForInstall = (
  tableRow,
  headerInputs,
  trapData
) => {
  const organizationId = getUserData("organizationId");

  const vigilanciaEntomologicaObject = {
    Address: {
      quadra: "",
      localDoImovel: "Rua",
      logradouro: tableRow.address,
      numero: tableRow.addressNumber,
      sequencial: tableRow.addressSequential,
      complemento: tableRow.addressComplement,
      tipoImovel: tableRow.propertyType,
      geographicNumber: "",
      cep: "",
    },
    DemandId: getDemandIdByOrganizationId[organizationId],
    ActivityDate: headerInputs.activityDate,
    InitialTime: headerInputs.activityDate,
    FinalTime: headerInputs.activityDate,
    VisitSituation: tableRow.visitStatus,
    RefusalReason: "",
    VisitNote: "",
    TypeForm: "Formulario ADI",
    OrganizationId: organizationId,
    CollectorId: headerInputs.teamsAndEmployees[0].id,
    CollectorName: "Form WEB",
    StandardInformations: {
      instalar: true,
      vistoriar: false,
      remover: false,
      firstToForm: true,
      typeFlatList: null,
      TypeForm: "Formulario ADI",
    },
    Estabelecimento: {
      NomeEstabelecimento: "",
    },
    Team: [],
    Residents: {
      TeamId: "00000000-0000-0000-0000-000000000000",
      CollectorId: headerInputs.teamsAndEmployees[0].id,
      DadosPrincipais: {
        Name: tableRow.responsablePersonName,
        Phone: tableRow.responsablePersonCellPhoneNumber,
        TypeDocument: "",
        NumberDocument: "",
        WhyDoesntHaveDocument: {
          Crianca: false,
          Estrangeiro: false,
          Indigena: false,
          SituacaoDeRua: false,
          Outro: false,
          DoesntHaveDocumentOther: "",
        },
        Gender: "",
        isPregnant: "",
      },
    },
    Midias: {
      WillTakeMidia: false,
      Photos: [],
    },
    exibirArmadilha: false,
    armadilha: {
      armadilhaParaMosquito: true,
      armadilhas: {
        armadilhaOvos: trapData.trapToInstall === "OVITRAMPA" ? true : false,
        armadilhaLarvas: false,
        armadilhaDisseminadoraInseticida:
          trapData.trapToInstall === "ADI" ? true : false,
        armadilhaMosquitoAdulto: false,
        armadilhaMosquitoAdulto2:
          trapData.trapToInstall === "BG-GAT" ? true : false,
        estacaoDisseminadoraLarvicida: false,
      },
      armadilhaOvos: [
        {
          isNumberEditable: true,
          loadedFirstInformations: false,
          armadilhaInstaladaNaVisita:
            trapData.trapToInstall === "OVITRAMPA" ? true : false,
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaOvos",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: true,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: true,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: true,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "Palhetas",
              quantidade: "1",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
        },
      ],
      armadilhaLarvas: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaLarvas",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
        },
      ],
      armadilhaDisseminadoraInseticida: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita:
            trapData.trapToInstall === "ADI" ? true : false,
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaDisseminadoraInseticida",
          latitude: trapData.latitude,
          longitude: trapData.longitude,
          numeroArmadilha: tableRow.trapNumber,
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: false,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          loadedFirstInformations: true,
        },
      ],
      armadilhaMosquitoAdulto: [
        {
          isNumberEditable: true,
          trapType: "armadilhaMosquitoAdulto",
          armadilhaInstaladaNaVisita: false,
          armadilhaLidaNaVisita: false,
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
        },
      ],
      armadilhaMosquitoAdulto2: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita:
            trapData.trapToInstall === "BG-GAT" ? true : false,
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaMosquitoAdulto2",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
        },
      ],
      estacaoDisseminadoraLarvicida: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          armadilhaLidaNaVisita: false,
          trapType: "estacaoDisseminadoraLarvicida",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: true,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
        },
      ],
    },
    exibirMosquito: false,
    mosquitos: {
      depositosPositivos: {
        a1: false,
        a2: false,
        b: false,
        c: false,
        d1: false,
        d2: false,
        e: false,
        pool: false,
      },
      a1: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        larvicidaL2: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        volumeAguaTratadaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidas: {
          colocadaTampa: false,
          colocadaTela: false,
          Outra: false,
          outraMedida: "",
        },
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          faltaEscada: false,
          outro: false,
          outroMotivo: "",
        },
      },
      a2: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        volumeAguaTratadaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidas: {
          colocadaTampa: false,
          colocadaTela: false,
          Outra: false,
          outraMedida: "",
        },
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      b: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      c: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      d1: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      d2: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      e: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      pool: {
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            quantidadeDepositos: "",
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
      },
    },
    sintomatico: {
      existeSintomatico: false,
    },
    latitude: trapData.latitude,
    longitude: trapData.longitude,
  };

  return vigilanciaEntomologicaObject;
};

export const getEntomologicVigilanceObjectForUninstallAdi = (
  tableRow,
  headerInputs,
  trapData
) => {
  const organizationId = getUserData("organizationId");

  const entomologicVigilinanceObject = {
    Address: {
      quadra: "",
      localDoImovel: "Rua",
      logradouro: tableRow.address,
      numero: tableRow.addressNumber,
      sequencial: tableRow.addressSequential,
      complemento: tableRow.addressComplement,
      tipoImovel: tableRow.propertyType,
      geographicNumber: "",
      cep: "",
    },
    DemandId: getDemandIdByOrganizationId[organizationId],
    ActivityDate: headerInputs.activityDate,
    InitialTime: headerInputs.activityDate,
    FinalTime: headerInputs.activityDate,
    VisitSituation: tableRow.visitStatus,
    RefusalReason: "",
    VisitNote: "",
    TypeForm: "Formulario ADI",
    OrganizationId: organizationId,
    CollectorId: headerInputs.teamsAndEmployees[0].id,
    CollectorName: "Form WEB",
    StandardInformations: {
      instalar: false,
      vistoriar: false,
      remover: true,
      firstToForm: true,
      typeFlatList: "ADI",
      TypeForm: "Formulario ADI",
    },
    Estabelecimento: {
      NomeEstabelecimento: "",
    },
    Team: [],
    Residents: {
      TeamId: "00000000-0000-0000-0000-000000000000",
      CollectorId: headerInputs.teamsAndEmployees[0].id,
      DadosPrincipais: {
        Name: headerInputs.responsablePersonName,
        Phone: headerInputs.responsablePersonCellPhoneNumber,
        TypeDocument: "",
        NumberDocument: "",
        WhyDoesntHaveDocument: {
          Crianca: false,
          Estrangeiro: false,
          Indigena: false,
          SituacaoDeRua: false,
          Outro: false,
          DoesntHaveDocumentOther: "",
        },
        Gender: "",
        isPregnant: "",
      },
    },
    Midias: {
      WillTakeMidia: false,
      Photos: [],
    },
    exibirArmadilha: false,
    armadilha: {
      armadilhaParaMosquito: true,
      armadilhas: {
        armadilhaOvos: false,
        armadilhaLarvas: false,
        armadilhaDisseminadoraInseticida: true,
        armadilhaMosquitoAdulto: false,
        armadilhaMosquitoAdulto2: false,
        estacaoDisseminadoraLarvicida: false,
      },
      armadilhaOvos: [
        {
          isNumberEditable: true,
          loadedFirstInformations: false,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaOvos",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: true,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: true,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: true,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "Palhetas",
              quantidade: "1",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
      armadilhaLarvas: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaLarvas",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
      armadilhaDisseminadoraInseticida: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaDisseminadoraInseticida",
          latitude: trapData.latitude,
          longitude: trapData.longitude,
          numeroArmadilha: tableRow.trapNumber,
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition:
            tableRow.isTrapInUsableConditions === "sim" ? true : false,
          readableConditionMotive: tableRow.whyIsTrapInUnusableConditions,
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 999,
              estimativa: "0",
            },
            larvaEstimativaMortas: {
              valor: 999,
              estimativa: "0",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 999,
              estimativa: "0",
            },
            pupaEstimativaMortas: {
              valor: 999,
              estimativa: "0",
            },
            aladosEstimativaVivas: {
              valor: 999,
              estimativa: "0",
            },
            aladosEstimativaMortas: {
              valor: 999,
              estimativa: "0",
            },
            larvaOuPurpa: "Selecione",
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: false,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
            trocaEtiqueta: tableRow.changedLabelNumber === "sim",
            trapNumberChange: {
              previousNumber: tableRow.trapNumber,
              newNumber: tableRow.newLabelNumber,
              dateOfChange: headerInputs.activityDate,
            }
          },
          amostras: tableRow.wasSampleCreated === "sim" ? true : false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: tableRow.sampleNumber,
              CapturedAedesAegypti: "Selecione",
              CapturedAedesAlbopictus: "Selecione",
              tipoDeAmostra: tableRow.sampleType,
              quantidade: "200",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: true,
          armadilhaRetiradaMotivo: tableRow.whyTrapWasRemoved,
          loadedFirstInformations: true,
        },
      ],
      armadilhaMosquitoAdulto: [
        {
          isNumberEditable: true,
          trapType: "armadilhaMosquitoAdulto",
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
      armadilhaMosquitoAdulto2: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaMosquitoAdulto2",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
      estacaoDisseminadoraLarvicida: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "estacaoDisseminadoraLarvicida",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: true,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
    },
    exibirMosquito: false,
    mosquitos: {
      depositosPositivos: {
        a1: false,
        a2: false,
        b: false,
        c: false,
        d1: false,
        d2: false,
        e: false,
        pool: false,
      },
      a1: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        larvicidaL2: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        volumeAguaTratadaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidas: {
          colocadaTampa: false,
          colocadaTela: false,
          Outra: false,
          outraMedida: "",
        },
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          faltaEscada: false,
          outro: false,
          outroMotivo: "",
        },
      },
      a2: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        volumeAguaTratadaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidas: {
          colocadaTampa: false,
          colocadaTela: false,
          Outra: false,
          outraMedida: "",
        },
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      b: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      c: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      d1: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      d2: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      e: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      pool: {
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            quantidadeDepositos: "",
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
      },
    },
    sintomatico: {
      existeSintomatico: false,
    },
    latitude: trapData.latitude,
    longitude: trapData.longitude,
  };

  return entomologicVigilinanceObject;
};

export const getEntomologicVigilanceObjectForUninstallGat = (
  tableRow,
  headerInputs,
  trapData
) => {
  const organizationId = getUserData("organizationId");

  const entomologicVigilanceObject = {
    Address: {
      quadra: "",
      localDoImovel: "Rua",
      logradouro: tableRow.address,
      numero: tableRow.addressNumber,
      sequencial: tableRow.addressSequential,
      complemento: tableRow.addressComplement,
      tipoImovel: tableRow.propertyType,
      geographicNumber: "",
      cep: "",
    },
    DemandId: getDemandIdByOrganizationId[organizationId],
    ActivityDate: headerInputs.activityDate,
    InitialTime: headerInputs.activityDate,
    FinalTime: headerInputs.activityDate,
    VisitSituation: tableRow.visitStatus,
    RefusalReason: "",
    VisitNote: "",
    TypeForm: "Formulario ADI",
    OrganizationId: organizationId,
    CollectorId: headerInputs.teamsAndEmployees[0].id,
    CollectorName: "Form WEB",
    StandardInformations: {
      instalar: false,
      vistoriar: false,
      remover: true,
      firstToForm: true,
      typeFlatList: "ADI",
      TypeForm: "Formulario ADI",
    },
    Estabelecimento: {
      NomeEstabelecimento: "",
    },
    Team: [],
    Residents: {
      TeamId: "00000000-0000-0000-0000-000000000000",
      CollectorId: headerInputs.teamsAndEmployees[0].id,
      DadosPrincipais: {
        Name: tableRow.responsablePersonName,
        Phone: tableRow.responsablePersonCellPhoneNumber,
        TypeDocument: "",
        NumberDocument: "",
        WhyDoesntHaveDocument: {
          Crianca: false,
          Estrangeiro: false,
          Indigena: false,
          SituacaoDeRua: false,
          Outro: false,
          DoesntHaveDocumentOther: "",
        },
        Gender: "",
        isPregnant: "",
      },
    },
    Midias: {
      WillTakeMidia: false,
      Photos: [],
    },
    exibirArmadilha: false,
    armadilha: {
      armadilhaParaMosquito: true,
      armadilhas: {
        armadilhaOvos: false,
        armadilhaLarvas: false,
        armadilhaDisseminadoraInseticida: false,
        armadilhaMosquitoAdulto: false,
        armadilhaMosquitoAdulto2: true,
        estacaoDisseminadoraLarvicida: false,
      },
      armadilhaOvos: [
        {
          isNumberEditable: true,
          loadedFirstInformations: false,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaOvos",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: true,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: true,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: true,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "Palhetas",
              quantidade: "1",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
      armadilhaLarvas: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaLarvas",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
      armadilhaDisseminadoraInseticida: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaDisseminadoraInseticida",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: 0,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: 0,
            },
            larvaOuPurpa: "Selecione",
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: false,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: "Selecione",
              CapturedAedesAlbopictus: "Selecione",
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
          loadedFirstInformations: true,
        },
      ],
      armadilhaMosquitoAdulto: [
        {
          isNumberEditable: true,
          trapType: "armadilhaMosquitoAdulto",
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
      armadilhaMosquitoAdulto2: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: tableRow.trapWasRead === "sim" ? true : false,
          trapType: "armadilhaMosquitoAdulto2",
          latitude: trapData.latitude,
          longitude: trapData.longitude,
          numeroArmadilha: tableRow.trapNumber,
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition:
            tableRow.isTrapInUsableConditions === "sim" ? true : false,
          readableConditionMotive: tableRow.whyIsTrapInUnusableConditions,
          resultadoCampoAmostras: {
            larva: tableRow.wereFoundLarvaeInTrap === "sim" ? true : false,
            larvaEstimativaVivas: {
              valor: tableRow.aliveLarvaeNumberSelectedInTrap,
              estimativa:
                getLarvaeEstimates[tableRow.aliveLarvaeNumberSelectedInTrap],
            },
            larvaEstimativaMortas: {
              valor: tableRow.deadLarvaeNumberSelectedInTrap,
              estimativa:
                getLarvaeEstimates[tableRow.deadLarvaeNumberSelectedInTrap],
            },
            pupas: tableRow.wereFoundPupaInTrap === "sim" ? true : false,
            pupaEstimativaVivas: {
              valor: tableRow.alivePupaNumberSelectedInTrap,
              estimativa:
                getPupaEstimates[tableRow.alivePupaNumberSelectedInTrap],
            },
            pupaEstimativaMortas: {
              valor: tableRow.deadPupaNumberSelectedInTrap,
              estimativa:
                getPupaEstimates[tableRow.deadPupaNumberSelectedInTrap],
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti:
              tableRow.wereAegyptiFoundInTrap === "sim" ? true : false,
            aedesAegyptiDados: {
              quantidade: tableRow.numberOfAegyptiInTrap,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus:
              tableRow.wereAlbopictusFoundInTrap === "sim" ? true : false,
            aedesAlbopictusDados: {
              quantidade: tableRow.numberOfAlbopictusInTrap,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos:
              tableRow.wereOthersMosquitoesFoundInTrap === "sim" ? true : false,
            outrosMosquitosDados: {
              quantidade: tableRow.numberOfOthersMosquitoesInTrap,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: tableRow.trapWasRead === "sim" ? true : false,
            defeito: {
              defeito:
                tableRow.whyIsTrapInUnusableConditions === "Peça com defeito"
                  ? true
                  : false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede:
                tableRow.whyIsTrapInUnusableConditionsSelectedOptions ===
                "Rede preta"
                  ? true
                  : false,
              palheta: false,
              cartao:
                tableRow.whyIsTrapInUnusableConditionsSelectedOptions ===
                "Cartão adesivo"
                  ? true
                  : false,
              funil:
                tableRow.whyIsTrapInUnusableConditionsSelectedOptions ===
                "Fúnil preto"
                  ? true
                  : false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: checkIfSpecificMaintenanceOcurred(
              "Adicionada inseticida",
              tableRow.trapWasReadOptions
            ),
            trocaIsca: false,
            trocaFitaAdesiva: checkIfSpecificMaintenanceOcurred(
              "Trocada fita adesiva",
              tableRow.trapWasReadOptions
            ),
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: checkIfSpecificMaintenanceOcurred(
              "Etiqueta deteriorada",
              tableRow.trapWasReadOptions
            ),
            adicionadoAgua: false,
            trocaAgua: checkIfSpecificMaintenanceOcurred(
              "Trocada a água",
              tableRow.trapWasReadOptions
            ),
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: checkIfSpecificMaintenanceOcurred(
              "Troca de Peça",
              tableRow.trapWasReadOptions
            ),
            realizadaLimpeza: checkIfSpecificMaintenanceOcurred(
              "Realizada limpeza",
              tableRow.trapWasReadOptions
            ),
            outraDescrever: "",
            trocaEtiqueta: tableRow.changedLabelNumber === "sim",
            trapNumberChange: {
              previousNumber: tableRow.trapNumber,
              newNumber: tableRow.newLabelNumber,
              dateOfChange: headerInputs.activityDate,
            }
          },
          amostras: tableRow.wasSampleCreated === "sim" ? true : false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: tableRow.sampleNumber,
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: tableRow.sampleType,
              quantidade: 1,
              mosquitosAdultos: parseInt(tableRow.sampleAdultMosquitoesNumber),
              mosquitosAdultosVivos: 0,
              mosquitosAdultosMortos: 0,
              mosquitoAedesAegypti: 0,
              mosquitoAedesAlbopictus: 0,
            },
          ],
          armadilhaRetirada: tableRow.wasTrapRemoved === "sim" ? true : false,
          armadilhaRetiradaMotivo: tableRow.whyWasTrapRemoved,
        },
      ],
      estacaoDisseminadoraLarvicida: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "estacaoDisseminadoraLarvicida",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: true,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
    },
    exibirMosquito: false,
    mosquitos: {
      depositosPositivos: {
        a1: false,
        a2: false,
        b: false,
        c: false,
        d1: false,
        d2: false,
        e: false,
        pool: false,
      },
      a1: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        larvicidaL2: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        volumeAguaTratadaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidas: {
          colocadaTampa: false,
          colocadaTela: false,
          Outra: false,
          outraMedida: "",
        },
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          faltaEscada: false,
          outro: false,
          outroMotivo: "",
        },
      },
      a2: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        volumeAguaTratadaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidas: {
          colocadaTampa: false,
          colocadaTela: false,
          Outra: false,
          outraMedida: "",
        },
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      b: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      c: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      d1: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      d2: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      e: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      pool: {
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            quantidadeDepositos: "",
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
      },
    },
    sintomatico: {
      existeSintomatico: false,
    },
    latitude: trapData.longitude,
    longitude: trapData.latitude,
  };

  return entomologicVigilanceObject;
};

export const getEntomologicVigilanceObjectForUninstallOvitrampa = (
  tableRow,
  headerInputs,
  trapData
) => {
  const organizationId = getUserData("organizationId");

  const entomologicVigilanceObject = {
    Address: {
      quadra: "",
      localDoImovel: "Rua",
      logradouro: tableRow.address,
      numero: tableRow.addressNumber,
      sequencial: tableRow.addressSequential,
      complemento: tableRow.addressComplement,
      tipoImovel: tableRow.propertyType,
      geographicNumber: "",
      cep: "",
    },
    DemandId: getDemandIdByOrganizationId[organizationId],
    ActivityDate: headerInputs.activityDate,
    InitialTime: headerInputs.activityDate,
    FinalTime: headerInputs.activityDate,
    VisitSituation: tableRow.visitStatus,
    RefusalReason: "",
    VisitNote: "",
    TypeForm: "Formulario ADI",
    OrganizationId: organizationId,
    CollectorId: headerInputs.teamsAndEmployees[0].id,
    CollectorName: "Form WEB",
    StandardInformations: {
      instalar: false,
      vistoriar: false,
      remover: true,
      firstToForm: true,
      typeFlatList: "ADI",
      TypeForm: "Formulario ADI",
    },
    Estabelecimento: {
      NomeEstabelecimento: "",
    },
    Team: [],
    Residents: {
      TeamId: "00000000-0000-0000-0000-000000000000",
      CollectorId: headerInputs.teamsAndEmployees[0].id,
      DadosPrincipais: {
        Name: tableRow.responsablePersonName,
        Phone: tableRow.responsablePersonCellPhoneNumber,
        TypeDocument: "",
        NumberDocument: "",
        WhyDoesntHaveDocument: {
          Crianca: false,
          Estrangeiro: false,
          Indigena: false,
          SituacaoDeRua: false,
          Outro: false,
          DoesntHaveDocumentOther: "",
        },
        Gender: "",
        isPregnant: "",
      },
    },
    Midias: {
      WillTakeMidia: false,
      Photos: [],
    },
    exibirArmadilha: false,
    armadilha: {
      armadilhaParaMosquito: true,
      armadilhas: {
        armadilhaOvos: true,
        armadilhaLarvas: false,
        armadilhaDisseminadoraInseticida: false,
        armadilhaMosquitoAdulto: false,
        armadilhaMosquitoAdulto2: false,
        estacaoDisseminadoraLarvicida: false,
      },
      armadilhaOvos: [
        {
          isNumberEditable: true,
          loadedFirstInformations: false,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: tableRow.trapWasRead === "sim" ? true : false,
          trapType: "armadilhaOvos",
          latitude: trapData.latitude,
          longitude: trapData.longitude,
          numeroArmadilha: tableRow.trapNumber,
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition:
            tableRow.isTrapInUsableConditions === "sim" ? true : false,
          readableConditionMotive: tableRow.whyIsTrapInUnusableConditions,
          resultadoCampoAmostras: {
            larva: tableRow.wereFoundLarvaeInTrap === "sim" ? true : false,
            larvaEstimativaVivas: {
              valor: tableRow.aliveLarvaeNumberSelectedInTrap,
              estimativa:
                getLarvaeEstimates[tableRow.aliveLarvaeNumberSelectedInTrap],
            },
            larvaEstimativaMortas: {
              valor: tableRow.deadLarvaeNumberSelectedInTrap,
              estimativa:
                getLarvaeEstimates[tableRow.deadLarvaeNumberSelectedInTrap],
            },
            pupas: tableRow.wereFoundPupaInTrap === "sim" ? true : false,
            pupaEstimativaVivas: {
              valor: tableRow.alivePupaNumberSelectedInTrap,
              estimativa:
                getPupaEstimates[tableRow.alivePupaNumberSelectedInTrap],
            },
            pupaEstimativaMortas: {
              valor: tableRow.deadPupaNumberSelectedInTrap,
              estimativa:
                getPupaEstimates[tableRow.deadPupaNumberSelectedInTrap],
            },
            aladosEstimativaVivas: {
              valor: tableRow.numberOfAliveMosquitoesInTrap,
              estimativa:
                getMosquitoEstimates[tableRow.numberOfAliveMosquitoesInTrap],
            },
            aladosEstimativaMortas: {
              valor: tableRow.numberOfDeadMosquitoesInTrap,
              estimativa:
                getMosquitoEstimates[tableRow.numberOfDeadMosquitoesInTrap],
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados:
              tableRow.areThereEggsOnStraw === "sim" ? true : false,
            ovosColetadosQuantidade: tableRow.numberOfEggsOnStraw,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: tableRow.trapWasRead === "sim" ? true : false,
            defeito: {
              defeito:
                tableRow.whyIsTrapInUnusableConditions === "Peça com defeito"
                  ? true
                  : false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip:
                tableRow.whyIsTrapInUnusableConditionsSelectedOptions === "Clip"
                  ? true
                  : false,
              pote:
                tableRow.whyIsTrapInUnusableConditionsSelectedOptions === "Pote"
                  ? true
                  : false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta:
                tableRow.whyIsTrapInUnusableConditionsSelectedOptions ===
                "Palheta"
                  ? true
                  : false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: checkIfSpecificMaintenanceOcurred(
              "Adicionada inseticida",
              tableRow.trapWasReadOptions
            ),
            trocaIsca: false,
            trocaFitaAdesiva: checkIfSpecificMaintenanceOcurred(
              "Trocada fita adesiva",
              tableRow.trapWasReadOptions
            ),
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: checkIfSpecificMaintenanceOcurred(
              "Etiqueta deteriorada",
              tableRow.trapWasReadOptions
            ),
            adicionadoAgua: false,
            trocaAgua: checkIfSpecificMaintenanceOcurred(
              "Trocada a água",
              tableRow.trapWasReadOptions
            ),
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: checkIfSpecificMaintenanceOcurred(
              "Troca de Peça",
              tableRow.trapWasReadOptions
            ),
            realizadaLimpeza: checkIfSpecificMaintenanceOcurred(
              "Realizada limpeza",
              tableRow.trapWasReadOptions
            ),
            outraDescrever: "",
            trocaEtiqueta: tableRow.changedLabelNumber === "sim",
            trapNumberChange: {
              previousNumber: tableRow.trapNumber,
              newNumber: tableRow.newLabelNumber,
              dateOfChange: headerInputs.activityDate,
            }
          },
          amostras: tableRow.wasSampleCreated === "sim" ? true : false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: tableRow.sampleNumber,
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: tableRow.sampleType,
              quantidade: "1",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: tableRow.wasTrapRemoved === "sim" ? true : false,
          armadilhaRetiradaMotivo: tableRow.whyWasTrapRemoved,
        },
      ],
      armadilhaLarvas: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaLarvas",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
      armadilhaDisseminadoraInseticida: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaDisseminadoraInseticida",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: 0,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: 0,
            },
            larvaOuPurpa: "Selecione",
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: false,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: "Selecione",
              CapturedAedesAlbopictus: "Selecione",
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
          loadedFirstInformations: true,
        },
      ],
      armadilhaMosquitoAdulto: [
        {
          isNumberEditable: true,
          trapType: "armadilhaMosquitoAdulto",
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
      armadilhaMosquitoAdulto2: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaMosquitoAdulto2",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: false,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
      estacaoDisseminadoraLarvicida: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "estacaoDisseminadoraLarvicida",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: true,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
    },
    exibirMosquito: false,
    mosquitos: {
      depositosPositivos: {
        a1: false,
        a2: false,
        b: false,
        c: false,
        d1: false,
        d2: false,
        e: false,
        pool: false,
      },
      a1: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        larvicidaL2: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        volumeAguaTratadaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidas: {
          colocadaTampa: false,
          colocadaTela: false,
          Outra: false,
          outraMedida: "",
        },
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          faltaEscada: false,
          outro: false,
          outroMotivo: "",
        },
      },
      a2: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        volumeAguaTratadaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidas: {
          colocadaTampa: false,
          colocadaTela: false,
          Outra: false,
          outraMedida: "",
        },
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      b: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      c: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      d1: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      d2: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      e: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      pool: {
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            quantidadeDepositos: "",
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
      },
    },
    sintomatico: {
      existeSintomatico: false,
    },
    latitude: trapData.latitude,
    longitude: trapData.longitude,
  };

  return entomologicVigilanceObject;
};

export const getEntomologicVigilanceObjectForMaintenanceAdi = (
  tableRow,
  headerInputs,
  trapData
) => {
  const organizationId = getUserData("organizationId");

  const entomologicVigilanceObject = {
    Address: {
      quadra: "",
      localDoImovel: "Rua",
      logradouro: tableRow.address,
      numero: tableRow.addressNumber,
      sequencial: tableRow.addressSequential,
      complemento: tableRow.addressComplement,
      tipoImovel: tableRow.propertyType,
      geographicNumber: "",
      cep: "",
    },
    DemandId: getDemandIdByOrganizationId[organizationId],
    ActivityDate: headerInputs.activityDate,
    InitialTime: headerInputs.activityDate,
    FinalTime: headerInputs.activityDate,
    VisitSituation: tableRow.visitStatus,
    RefusalReason: "",
    VisitNote: "",
    TypeForm: "Formulario ADI",
    OrganizationId: organizationId,
    CollectorId: headerInputs.teamsAndEmployees[0].id,
    CollectorName: "Form WEB",
    StandardInformations: {
      instalar: false,
      vistoriar: true,
      remover: false,
      firstToForm: true,
      typeFlatList: "ADI",
      TypeForm: "Formulario ADI",
    },
    Estabelecimento: {
      NomeEstabelecimento: "",
    },
    Team: [],
    Residents: {
      TeamId: "00000000-0000-0000-0000-000000000000",
      CollectorId: headerInputs.teamsAndEmployees[0].id,
      DadosPrincipais: {
        Name: tableRow.responsablePersonName,
        Phone: tableRow.responsablePersonCellPhoneNumber,
        TypeDocument: "",
        NumberDocument: "",
        WhyDoesntHaveDocument: {
          Crianca: false,
          Estrangeiro: false,
          Indigena: false,
          SituacaoDeRua: false,
          Outro: false,
          DoesntHaveDocumentOther: "",
        },
        Gender: "",
        isPregnant: "",
      },
    },
    Midias: {
      WillTakeMidia: false,
      Photos: [],
    },
    exibirArmadilha: false,
    armadilha: {
      armadilhaParaMosquito: true,
      armadilhas: {
        armadilhaOvos: false,
        armadilhaLarvas: false,
        armadilhaDisseminadoraInseticida: true,
        armadilhaMosquitoAdulto: false,
        armadilhaMosquitoAdulto2: false,
        estacaoDisseminadoraLarvicida: false,
      },
      armadilhaOvos: [
        {
          isNumberEditable: true,
          loadedFirstInformations: false,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaOvos",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: true,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: true,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: true,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "Palhetas",
              quantidade: "1",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
      armadilhaLarvas: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaLarvas",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
      armadilhaDisseminadoraInseticida: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: tableRow.trapWasRead === "sim" ? true : false,
          trapType: "armadilhaDisseminadoraInseticida",
          latitude: trapData.latitude,
          longitude: trapData.longitude,
          numeroArmadilha: tableRow.trapNumber,
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition:
            tableRow.isTrapInUsableConditions === "sim" ? true : false,
          readableConditionMotive: tableRow.whyIsTrapInUnusableConditions,
          resultadoCampoAmostras: {
            larva: tableRow.wereFoundLarvaeInTrap === "sim" ? true : false,
            larvaEstimativaVivas: {
              valor: tableRow.aliveLarvaeNumberSelectedInTrap,
              estimativa:
                getLarvaeEstimates[tableRow.aliveLarvaeNumberSelectedInTrap],
            },
            larvaEstimativaMortas: {
              valor: tableRow.deadLarvaeNumberSelectedInTrap,
              estimativa:
                getLarvaeEstimates[tableRow.deadLarvaeNumberSelectedInTrap],
            },
            pupas: tableRow.wereFoundPupaInTrap === "sim" ? true : false,
            pupaEstimativaVivas: {
              valor: tableRow.alivePupaNumberSelectedInTrap,
              estimativa:
                getPupaEstimates[tableRow.alivePupaNumberSelectedInTrap],
            },
            pupaEstimativaMortas: {
              valor: tableRow.deadPupaNumberSelectedInTrap,
              estimativa:
                getPupaEstimates[tableRow.deadPupaNumberSelectedInTrap],
            },
            aladosEstimativaVivas: {
              valor: tableRow.aliveMosquitoNumberSelectedInTrap,
              estimativa:
                getMosquitoEstimates[
                  tableRow.aliveMosquitoNumberSelectedInTrap
                ],
            },
            aladosEstimativaMortas: {
              valor: tableRow.deadMosquitoNumberSelectedInTrap,
              estimativa:
                getMosquitoEstimates[tableRow.deadMosquitoNumberSelectedInTrap],
            },
            larvaOuPurpa: "Selecione",
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: tableRow.trapWasRead === "sim" ? true : false,
            defeito: {
              defeito:
                tableRow.whyIsTrapInUnusableConditions === "Peça com defeito"
                  ? true
                  : false,
              indicadorTempo:
                tableRow.whyIsTrapInUnusableConditionsSelectedOptions ===
                "Indicador de tempo"
                  ? true
                  : false,
              tampa:
                tableRow.whyIsTrapInUnusableConditionsSelectedOptions ===
                "Tampa"
                  ? true
                  : false,
              flutuador:
                tableRow.whyIsTrapInUnusableConditionsSelectedOptions ===
                "Flutuador"
                  ? true
                  : false,
              balde:
                tableRow.whyIsTrapInUnusableConditionsSelectedOptions ===
                "Balde"
                  ? true
                  : false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: checkIfSpecificMaintenanceOcurred(
              "Trocado Refil",
              tableRow.trapWasReadOptions
            ),
            etiquetaDeteriorada: checkIfSpecificMaintenanceOcurred(
              "Etiqueta deteriorada",
              tableRow.trapWasReadOptions
            ),
            adicionadoAgua: checkIfSpecificMaintenanceOcurred(
              "Adicionada água",
              tableRow.trapWasReadOptions
            ),
            trocaAgua: checkIfSpecificMaintenanceOcurred(
              "Trocada a água",
              tableRow.trapWasReadOptions
            ),
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: checkIfSpecificMaintenanceOcurred(
              "Troca de Peça",
              tableRow.trapWasReadOptions
            ),
            realizadaLimpeza: checkIfSpecificMaintenanceOcurred(
              "Realizada limpeza",
              tableRow.trapWasReadOptions
            ),
            outra: false,
            outraDescrever: "",
            trocaEtiqueta: tableRow.changedLabelNumber === "sim",
            trapNumberChange: {
              previousNumber: tableRow.trapNumber,
              newNumber: tableRow.newLabelNumber,
              dateOfChange: headerInputs.activityDate,
            }
          },
          amostras: tableRow.wasSampleCreated === "sim" ? true : false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: tableRow.sampleNumber,
              CapturedAedesAegypti: "Selecione",
              CapturedAedesAlbopictus: "Selecione",
              tipoDeAmostra: tableRow.sampleType,
              quantidade: "200",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
          loadedFirstInformations: true,
        },
      ],
      armadilhaMosquitoAdulto: [
        {
          isNumberEditable: true,
          trapType: "armadilhaMosquitoAdulto",
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
      armadilhaMosquitoAdulto2: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "armadilhaMosquitoAdulto2",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: false,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
      estacaoDisseminadoraLarvicida: [
        {
          isNumberEditable: true,
          armadilhaInstaladaNaVisita: false,
          observationsTrap: "",
          armadilhaLidaNaVisita: false,
          trapType: "estacaoDisseminadoraLarvicida",
          latitude: "",
          longitude: "",
          numeroArmadilha: "",
          predadores: false,
          lagartixa: false,
          aranha: false,
          formiga: false,
          outroPredador: false,
          outroPredadorDescrever: "",
          isReadableCondition: true,
          readableConditionMotive: "",
          resultadoCampoAmostras: {
            larva: false,
            larvaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            larvaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            pupas: false,
            pupaEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            pupaEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaVivas: {
              valor: 0,
              estimativa: "",
            },
            aladosEstimativaMortas: {
              valor: 0,
              estimativa: "",
            },
            larvaOuPurpa: false,
            larvasVivas: 0,
            larvasMortas: 0,
            pupasVivas: 0,
            pupasMortas: 0,
            aedesAegypti: false,
            aedesAegyptiDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            aedesAlbopictus: false,
            aedesAlbopictusDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            outrosMosquitos: false,
            outrosMosquitosDados: {
              quantidade: 0,
              quantidadeVivos: 0,
              quantidadeMortos: 0,
            },
            ovosColetados: false,
            ovosColetadosQuantidade: 0,
          },
          emergenciaMosquitoArmadilha: {
            emergenciaMosquitoArmadilha: false,
            exuvia: false,
            mosquitoEmergindoPousadoAgua: false,
            mosquitoMortoAgua: false,
          },
          manutencao: {
            manutencao: true,
            defeito: {
              defeito: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            defeitoTroca: {
              defeitoTroca: false,
              indicadorTempo: false,
              tampa: false,
              flutuador: false,
              balde: false,
              pano: false,
              clip: false,
              pote: false,
              alca: false,
              tela: false,
              gaiola: false,
              cone: false,
              vaso: false,
              pneu: false,
              rede: false,
              palheta: false,
              cartao: false,
              funil: false,
            },
            trocaInseticida: false,
            adicionadoInseticida: false,
            trocaIsca: false,
            trocaFitaAdesiva: false,
            trocaPalheta: false,
            trocaRefil: false,
            etiquetaDeteriorada: false,
            adicionadoAgua: true,
            trocaAgua: false,
            trocaBateria: false,
            trocaTela: false,
            trocaPeca: false,
            realizadaLimpeza: false,
            outra: false,
            outraDescrever: "",
          },
          amostras: false,
          amostrasColetadas: [
            {
              isNumberEditable: true,
              numeroDaAmostra: "",
              CapturedAedesAegypti: false,
              CapturedAedesAlbopictus: false,
              tipoDeAmostra: "",
              quantidade: "",
              mosquitosAdultos: "",
              mosquitosAdultosVivos: "",
              mosquitosAdultosMortos: "",
              mosquitoAedesAegypti: "",
              mosquitoAedesAlbopictus: "",
            },
          ],
          armadilhaRetirada: false,
          armadilhaRetiradaMotivo: "",
        },
      ],
    },
    exibirMosquito: false,
    mosquitos: {
      depositosPositivos: {
        a1: false,
        a2: false,
        b: false,
        c: false,
        d1: false,
        d2: false,
        e: false,
        pool: false,
      },
      a1: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        larvicidaL2: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        volumeAguaTratadaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidas: {
          colocadaTampa: false,
          colocadaTela: false,
          Outra: false,
          outraMedida: "",
        },
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          faltaEscada: false,
          outro: false,
          outroMotivo: "",
        },
      },
      a2: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        volumeAguaTratadaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidas: {
          colocadaTampa: false,
          colocadaTela: false,
          Outra: false,
          outraMedida: "",
        },
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      b: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      c: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      d1: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      d2: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      e: {
        quantidadeDepositos: "",
        capacidadeDepositos: "",
        depositoInspecionado: false,
        encontradoMosquitoImaturo: false,
        quantidadeDepositoMosquitoImaturo: "",
        amostras: false,
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
        tratamentoLarvicida: false,
        larvicidaL1: false,
        quantidadeDepositosL1: "",
        quantidadeProdutosL1: "",
        volumeAguaTratadaL1: "",
        larvicidaL1Utilizado: "",
        outroLarvicidaL1: "",
        larvicidaL2: false,
        quantidadeDepositosL2: "",
        quantidadeProdutosL2: "",
        volumeAguaTratadaL2: "",
        larvicidaL2Utilizado: "",
        outroLarvicidaL2: "",
        depositoEliminado: false,
        quantidadeDepositoEliminado: "",
        realizadaOutraMedida: false,
        medidaAdotada: "",
        depositoNaoInspecionado: false,
        motivoNaoInspecionado: {
          inacessivel: false,
          outro: false,
          outroMotivo: "",
        },
      },
      pool: {
        amostrasColetadas: [
          {
            isNumberEditable: true,
            numeroDaAmostra: "",
            CapturedAedesAegypti: false,
            CapturedAedesAlbopictus: false,
            quantidadeDepositos: "",
            tipoDeAmostra: "",
            quantidade: 0,
            quantidadeViva: 0,
            quantidadeMorta: 0,
          },
        ],
      },
    },
    sintomatico: {
      existeSintomatico: false,
    },
    latitude: trapData.latitude,
    longitude: trapData.longitude,
  };

  return entomologicVigilanceObject;
};
