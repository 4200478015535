import React from "react";

import {
  Button,
  Col,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
} from "reactstrap";
import LoadingSpin from "react-loading-spin";
import {
  fetchTrapTypesData,
  getTerritorializationsIdsToSendOnRequest,
  getUsersIdsToSendOnRequest,
  trapsColorStatusesOptionsList,
  trapsStatusesOptionsList,
} from "../../../../services/utils/globalFunctions";
import moment from "moment";
import useDemandsList from "../../../../hooks/useDemandsList";
import useTrapTypesList from "../../../../hooks/useTrapTypesList";
import useTerritorializationsList from "../../../../hooks/useTerritorializationsList";
import useTeamsList from "../../../../hooks/useTeamsList";
import useTypeLayersList from "../../../../hooks/useTypeLayersList";
import useEmployeesList from "../../../../hooks/useEmployeesList";
import { toast } from "react-toastify";
import useUserPreferences from "../../../../hooks/useUserPreferences";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import useTrapsList from "../../../../hooks/useTrapsList";
import { getTrapRange } from "../../../../constants/RangeTrapConstant";
import { getUserData } from "../../../../services/utils/auth";
import { fetchFilteredTraps } from "../../../../services/api/Trap";
import useLoading from "../Hooks/useLoading";
import { DateFilter } from "../../../../components/Filters/DateFilter";
import DemandsFilter from "../../../../components/Filters/DemandsFilter";
import TrapTypesFilter from "../../../../components/Filters/TrapTypesFilter";
import UsersFilter from "../../../../components/Filters/UsersFilter";
import TeamsFilter from "../../../../components/Filters/TeamsFilter";
import TypeLayersFilter from "../../../../components/Filters/TypeLayersFilter";
import TerritorializationsFilter from "../../../../components/Filters/TerritorializationsFilter";
import { PreferencesModalWrapper } from "../Modals/PreferencesModal";
import styles from "./TrapsPageFilters.module.css";
import { getTodayEpidemiologicalWeek } from "../../../../services/utils/todayEpidemiologicalWeek";
import YearsFilter from "../../../../components/Filters/YearsFilter";
import { EpidemiologicWeekFilter } from "../../../../components/Filters/EpidemiologicWeekFilter";

import "./popoverStyles.css";
import PropertyTypeFilter from "../../../../components/Filters/PropertyTypeFilter";
import { PROPERTY_TYPES_OPTIONS } from "../../../../constants/WebFormConstants";
import useProjectsList from "../../../../hooks/useProjectsList";
import ProjectsFilter from "../../../../components/Filters/ProjectsFilter";

const DEFAULT_FILTERS = {
  beginDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
  colorStatuses: trapsColorStatusesOptionsList,
  insideTerritorialization: "",
  demand: {
    label: "Todas",
    value: "",
  },
  filtersTypes: {
    usersIds: 1,
  },
  employees: [],
  endDate: moment().format("YYYY-MM-DD"),
  statuses: trapsStatusesOptionsList,
  team: {
    label: "Todos",
    members: [],
    value: "",
  },
  territorializations: [],
  trapType: {
    label: "Selecione",
    trapTypeName: "",
    value: "",
  },
  typeLayers: [],
  year: {
    label: "Selecione",
    value: "",
  },
  epidemiologicalWeekEnd: {
    label: "Selecione",
    value: "",
  },
  propertyType: [],
  projects: [],
};

const useTrapsPageFilters = () => {
  const [filters, setFilters] = React.useState(DEFAULT_FILTERS);
  const { demandsList } = useDemandsList();
  const { employeesList } = useEmployeesList();
  const { setTrapsList } = useTrapsList();
  const { teamsList } = useTeamsList();
  const { territorializationsList } = useTerritorializationsList();
  const { trapTypesList } = useTrapTypesList();
  const { typeLayersList } = useTypeLayersList();
  const { preferences } = useUserPreferences();
  const { projectsList } = useProjectsList();
  const { setAppliedFilters } = useAppliedFilters();
  const { isTrapsListLoading, setIsTrapsListLoading } = useLoading();

  React.useEffect(() => {
    if (
      !demandsList ||
      demandsList.length === 0 ||
      !employeesList ||
      employeesList.length === 0 ||
      !preferences ||
      !teamsList ||
      !teamsList.length === 0 ||
      !territorializationsList ||
      territorializationsList.length === 0 ||
      !trapTypesList ||
      trapTypesList.length === 0
    )
      return;

    applyUserFiltersPreference();
  }, [
    demandsList,
    employeesList,
    preferences,
    teamsList,
    territorializationsList,
    trapTypesList,
  ]);

  const applyUserFiltersPreference = async () => {
    const organizationId = getUserData("organizationId");

    const adiTrapOption = trapTypesList.find(
      ({ trapTypeName }) => trapTypeName === "armadilhaDisseminadoraInseticida"
    );

    try {
      if (!("trapsPage" in preferences)) {
        if (organizationId === "8e34767e-3673-4606-9e47-dd67edd2677d") {
          const ovitrapOption = trapTypesList.find(
            ({ trapTypeName }) => trapTypeName === "armadilhaOvos"
          );

          setFilters((previousValues) => {
            const newValues = { ...previousValues };
            newValues.trapType = ovitrapOption;

            fetchAllComponentsData(newValues);

            return newValues;
          });

          return;
        } else {
          setFilters((previousValues) => {
            const newValues = { ...previousValues };
            newValues.trapType = adiTrapOption;

            fetchAllComponentsData(newValues);

            return newValues;
          });
        }

        return;
      }

      setFilters((previousValues) => {
        const newValues = { ...previousValues };

        newValues.demand = preferences.trapsPage.demand;
        newValues.trapType = preferences.trapsPage.trapType;
        newValues.employees = preferences.trapsPage.employee;
        newValues.team = preferences.trapsPage.team;
        newValues.territorializations =
          preferences.trapsPage.territorialization;

        if (newValues.trapType.value === "") newValues.trapType = adiTrapOption;

        fetchAllComponentsData(newValues);

        return newValues;
      });
    } catch (error) {
      if (error instanceof Error) {
        toast.error(
          "Ocorreu um erro ao definir as preferências. Aplique manualmente os filtres e clique no botão 'Filtrar'."
        );
        console.error(error.message);
      }
    }
  };

  const clearFilters = () => {
    setFilters(DEFAULT_FILTERS);
  };

  const fetchAllComponentsData = React.useCallback(
    async (activeTrapsFilters) => {
      setTrapsList([]);

      console.log("entrei aqui");

      setAppliedFilters(activeTrapsFilters);

      await Promise.all([fetchTrapsListData(activeTrapsFilters)]);
    },
    []
  );

  const fetchTrapsListData = async (filters) => {
    if (filters.trapType.value === "") {
      toast.error("É necessário selecionar um tipo de armadilha.");

      return;
    }

    if (filters.year.value === "" && filters.endDate === "") {
      toast.error(
        "É necessário selecionar ao menos um filtro de temporalização (data ou ano)"
      );

      return;
    }

    if (
      filters.year.value !== "" &&
      filters.epidemiologicalWeekEnd.value === ""
    ) {
      toast.error("É necessário selecionar uma semana epidemiológica.");

      return;
    }

    setIsTrapsListLoading(true);

    const trapTypes = await fetchTrapTypesData();

    const trapsRangesToSend = trapTypes.map(({ trapTypeName, value }) => {
      const { quantityRange, data } = getTrapRange(trapTypeName);

      return {
        trapTypeId: value,
        quantity: quantityRange,
        begin: data.beggining,
        middle: !data.middle ? null : data.middle,
        end: data.end,
      };
    });

    const organizationId = getUserData("organizationId");
    const usersIdsToSend = getUsersIdsToSendOnRequest(filters);
    const territorializationsIdsToSend =
      getTerritorializationsIdsToSendOnRequest(filters);

    const filtersToSend = {
      colorStatuses: filters.colorStatuses.map(({ value }) => value),
      demandsIds: [],
      finalDate:
        filters.year.value === ""
          ? moment(filters.endDate)
              .utc()
              .set({ hour: 23, minute: 59, second: 59 })
              .toISOString()
          : moment(filters.epidemiologicalWeekEnd.value, "DD/MM/YYYY")
              .utc()
              .set({ hour: 23, minute: 59, second: 59 })
              .toISOString(),
      organizationId,
      propertyType: filters.propertyType.map(({ value }) => value),
      projectsIds: filters.projects.map(({ value }) => value),
      ranges: trapsRangesToSend,
      statuses: filters.statuses.map(({ value }) => value),
      territorializationsIds: territorializationsIdsToSend,
      trapsNumbers: [],
      trapTypesIds: [],
      usersIds: usersIdsToSend,
    };

    try {
      const { data, status } = await fetchFilteredTraps(filtersToSend);

      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelas armadilhas. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      setTrapsList(data);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error.message);
      }
    } finally {
      setIsTrapsListLoading(false);
    }
  };

  const handleEpidemiologicWeeksFilterChange = React.useCallback(
    (filterName, newValue) => {
      setFilters((previousValues) => {
        const newValues = { ...previousValues };
        newValues[filterName] = newValue;

        return newValues;
      });
    },
    [filters]
  );

  const handleEndDateChange = (filterName, newValue) => {
    setFilters((previousValues) => {
      const newValues = { ...previousValues };
      newValues[filterName] = newValue;

      newValues["year"] = {
        label: "Selecione",
        value: "",
      };

      newValues["epidemiologicalWeekEnd"] = {
        label: "Selecione",
        value: "",
      };

      return newValues;
    });
  };

  const handleFiltersChange = (filterName, newValue) => {
    setFilters((previousValues) => {
      const newValues = { ...previousValues };

      newValues[filterName] = newValue;

      return newValues;
    });
  };

  const handleFiltersTypesChange = (filterTypeName) => {
    setFilters((previousValues) => {
      const newValues = { ...previousValues };

      newValues.filtersTypes[filterTypeName] =
        newValues.filtersTypes[filterTypeName] === 1 ? 2 : 1;

      return newValues;
    });
  };

  const handleTypeLayerChange = (value) => {
    handleFiltersChange(
      "typeLayers",
      !value || value.length === 0 ? [] : value
    );

    if (!value || value.length === 0)
      handleFiltersChange("territorializations", []);
    else {
      const allTerritorializationsToSet = [];

      value.forEach(({ territorializations }) => {
        allTerritorializationsToSet.push(...territorializations);
      });

      handleFiltersChange("territorializations", allTerritorializationsToSet);
    }
  };

  const handleYearFilterChange = React.useCallback(
    (filterName, filterValue) => {
      setFilters((previousValues) => {
        const newValues = { ...previousValues };
        newValues[filterName] = filterValue;

        newValues.endDate = "";

        return newValues;
      });
    },
    []
  );

  return {
    clearFilters,
    demandsList,
    employeesList,
    fetchAllComponentsData,
    filters,
    handleEpidemiologicWeeksFilterChange,
    handleEndDateChange,
    handleFiltersChange,
    handleFiltersTypesChange,
    handleTypeLayerChange,
    handleYearFilterChange,
    isTrapsListLoading,
    projectsList,
    teamsList,
    territorializationsList,
    trapTypesList,
    typeLayersList,
  };
};

const usePopover = () => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const togglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  return {
    isPopoverOpen,
    togglePopover,
  };
}

const TrapsPageFilters = () => {
  const { isPopoverOpen, togglePopover } = usePopover();

  return (
    <section className={styles.container}>
      <Button
        className={styles.actionsButton}
        id="mainFiltersPopover"
        type="button"
      >
        <i className="fa fa-filter"></i> Filtros
      </Button>
      <TrapsPageFiltersPopover
        offset="-120, 8"
        placement="bottom"
        target="mainFiltersPopover"
        isOpen={isPopoverOpen}
        toggle={togglePopover}
      />
      <PreferencesModalWrapper />
    </section>
  );
};

const TrapsPageFiltersPopover = ({
  target,
  offset,
  placement,
  isOpen,
  toggle,
  ...props
}) => {
  const {
    clearFilters,
    demandsList,
    employeesList,
    fetchAllComponentsData,
    filters,
    handleEpidemiologicWeeksFilterChange,
    handleEndDateChange,
    handleFiltersChange,
    /* handleFiltersTypesChange, */
    handleTypeLayerChange,
    handleYearFilterChange,
    isTrapsListLoading,
    projectsList,
    teamsList,
    territorializationsList,
    trapTypesList,
    typeLayersList,
  } = useTrapsPageFilters();

  return (
    <Popover
      style={{ width: "500px", zIndex: 100000 }}
      placement={placement}
      target={target}
      offset={offset}
      isOpen={isOpen}
      toggle={toggle}
      id="filtersPopover"
      {...props}
    >
      <PopoverHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Filtros
          <span style={{ fontSize: "12px", fontWeight: 400 }}>
            Semana epidemiológica atual: {getTodayEpidemiologicalWeek()}
          </span>
          <i
            className="fa fa-times"
            onClick={toggle}
            style={{ cursor: "pointer" }}
          ></i>
        </div>
      </PopoverHeader>
      <PopoverBody style={{ backgroundColor: "#ffffff" }}>
        <span
          style={{
            display: "block",
            textAlign: "right",
            fontSize: "11px",
          }}
          className={styles.label}
        >
          ( <span style={{ color: "red" }}>*</span> ) : Campo obrigatório
        </span>
        <Row className="mb-2">
          <Col>
            <label htmlFor="endDate" className={styles.label}>
              Instaladas até a data
              {filters.year.value !== "" ? (
                ""
              ) : (
                <div>
                  {" "}
                  (<span style={{ color: "red" }}>*</span>)
                </div>
              )}
              :
            </label>
            <DateFilter
              filterName="endDate"
              handleFiltersChange={handleEndDateChange}
              id="endDate"
              value={filters.endDate}
              disabled={filters.year.value !== ""}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label htmlFor="" className={styles.label}>
              Ano
              {filters.endDate !== "" ? (
                ""
              ) : (
                <div>
                  {" "}
                  (<span style={{ color: "red" }}>*</span>)
                </div>
              )}
              :
            </label>
            <YearsFilter
              filterName="year"
              handleFiltersChange={handleYearFilterChange}
              value={filters.year}
              isDisabled={filters.endDate !== ""}
            />
          </Col>
          <Col>
            <div>
              <label htmlFor="" className={styles.label}>
                Instaladas até a semana:{" "}
              </label>
              <EpidemiologicWeekFilter
                filterName="epidemiologicalWeekEnd"
                handleFiltersChange={handleEpidemiologicWeeksFilterChange}
                value={filters.epidemiologicalWeekEnd}
                year={String(filters.year.value)}
                isDisabled={filters.year.value === ""}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label htmlFor="" className={styles.label}>
              Tipo de atividade:
            </label>
            <ProjectsFilter
              handleFiltersChange={handleFiltersChange}
              options={projectsList}
              value={filters.projects}
              filterName={"projects"}
              isDisabled={projectsList.length === 0}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label htmlFor="" className={styles.label}>
              Tipo de imóvel:
            </label>
            <PropertyTypeFilter
              handleFiltersChange={handleFiltersChange}
              options={PROPERTY_TYPES_OPTIONS}
              value={filters.propertyType}
              filterName={"propertyType"}
            />
          </Col>
          <Col>
            <label htmlFor="" className={styles.label}>
              Tipo de armadilha (<span style={{ color: "red" }}>*</span>) :
            </label>
            <TrapTypesFilter
              filterName="trapType"
              handleFiltersChange={handleFiltersChange}
              options={trapTypesList}
              value={filters.trapType}
              isDisabled={trapTypesList.length === 0}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label htmlFor="" className={styles.label}>
              Demanda:
            </label>
            <DemandsFilter
              filterName="demand"
              handleFiltersChange={handleFiltersChange}
              options={demandsList}
              value={filters.demand}
              isDisabled={demandsList.length === 0}
            />
          </Col>
          <Col>
            <label htmlFor="" className={styles.label}>
              Usuários:{" "}
              {/* (
              {filters.filtersTypes.usersIds === 1 ? (
                <div>
                  <span
                    data-tip
                    data-for="inclusive1"
                    style={{ color: "#3b6bca", textDecoration: "underline" }}
                  >
                    Inclusivo
                  </span>
                  <ReactTooltip
                    place="top"
                    effect="solid"
                    type="info"
                    id="inclusive1"
                  >
                    Filtra os resultados pelos usuários selecionados.
                  </ReactTooltip>
                </div>
              ) : (
                <div>
                  <span
                    data-tip
                    data-for="exclusive1"
                    style={{ color: "#3b6bca", textDecoration: "underline" }}
                  >
                    Exclusivo
                  </span>
                  <ReactTooltip
                    place="top"
                    effect="solid"
                    type="info"
                    id="exclusive1"
                  >
                    Filtra os resultados exceto dos usuários selecionados.
                  </ReactTooltip>
                </div>
              )}
              ){" "}
              <i onClick={() => handleFiltersTypesChange("usersIds")}>
                <ExchangeIcon />
              </i> */}
            </label>
            <UsersFilter
              filterName="employees"
              handleFiltersChange={handleFiltersChange}
              options={employeesList}
              value={filters.employees}
              isDisabled={employeesList.length === 0}
            />
          </Col>
          <Col>
            <label htmlFor="" className={styles.label}>
              Equipe:{" "}
              {/* (
              {filters.filtersTypes.usersIds === 1 ? (
                <div>
                  <span
                    data-tip
                    data-for="inclusive2"
                    style={{ color: "#3b6bca", textDecoration: "underline" }}
                  >
                    Inclusivo
                  </span>
                  <ReactTooltip
                    place="top"
                    effect="solid"
                    type="info"
                    id="inclusive2"
                  >
                    Filtra os resultados pela equipe selecionada.
                  </ReactTooltip>
                </div>
              ) : (
                <div>
                  <span
                    data-tip
                    data-for="exclusive2"
                    style={{ color: "#3b6bca", textDecoration: "underline" }}
                  >
                    Exclusivo
                  </span>
                  <ReactTooltip
                    place="top"
                    effect="solid"
                    type="info"
                    id="exclusive2"
                  >
                    Filtra os resultados exceto da equipe selecionada.
                  </ReactTooltip>
                </div>
              )}
              ){" "}
              <i onClick={() => handleFiltersTypesChange("usersIds")}>
                <ExchangeIcon />
              </i> */}
            </label>
            <TeamsFilter
              filterName="team"
              handleFiltersChange={handleFiltersChange}
              options={teamsList}
              value={filters.team}
              isDisabled={teamsList.length === 0}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label htmlFor="" className={styles.label}>
              Delimitador de área:
            </label>
            <TypeLayersFilter
              handleTypeLayerChange={handleTypeLayerChange}
              typeLayersList={typeLayersList}
              value={filters.typeLayers}
              isDisabled={typeLayersList.length === 0}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <label htmlFor="" className={styles.label}>
              Territorializações:
            </label>
            <TerritorializationsFilter
              handleFiltersChange={handleFiltersChange}
              territorializationsList={territorializationsList}
              value={filters.territorializations}
              isDisabled={territorializationsList.length === 0}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            {demandsList.length === 0 ||
            employeesList.length === 0 ||
            teamsList.length === 0 ||
            trapTypesList.length === 0 ||
            territorializationsList.length === 0 ? (
              <Button disabled={true} color="primary">
                Carregando preferencias...
              </Button>
            ) : isTrapsListLoading ? (
              <Button disabled={true} color="primary">
                <LoadingSpin color="#fff" size={17} />
              </Button>
            ) : (
              <Button
                onClick={() => fetchAllComponentsData(filters)}
                color="primary"
              >
                Aplicar filtros
              </Button>
            )}
          </Col>
          <Col>
            <Button onClick={clearFilters}>Remover filtros</Button>
          </Col>
        </Row>
      </PopoverBody>
    </Popover>
  );
};

/* const ExchangeIcon = () => {
  return (
    <img
      src={require("../../../../assets/img/icons/interface/exchange-black.png")}
      style={{
        width: "12px",
        height: "12px",
        cursor: "pointer",
        marginLeft: "5px",
      }}
      alt="icone"
    />
  );
}; */

export default TrapsPageFilters;