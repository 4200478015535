import React from "react";

import { Select } from "../Wrappers/SelectAll";
import { selectComponentStyles } from "../../services/utils/globalFunctions";
import getWeeks from "../../services/utils/epidemiologicalWeek";

export const EpidemiologicWeekFilter = ({
  filterName,
  handleFiltersChange,
  value,
  year,
  ...props
}) => {
  const [epidemiologicalWeeksOptionsList, setEpidemiologicalWeeksOptionsList] =
    React.useState([]);

  React.useEffect(() => {
    const epidemiologicalWeeksFromYear = getWeeks().find(
      (week) => week.year === year
    );

    if (!epidemiologicalWeeksFromYear) return;

    const epidemiologicalWeeksList = epidemiologicalWeeksFromYear.weeks.map(
      ({ beginDate, endDate, label }) => ({
        beginDate,
        endDate,
        label: label.replace("Semana ", ""),
        value: endDate,
      })
    );

    setEpidemiologicalWeeksOptionsList(epidemiologicalWeeksList);
  }, [year]);

  return (
    <div>
      <Select
        styles={selectComponentStyles}
        placeholder={"Selecione"}
        isMulti={false}
        isClearable={true}
        value={value}
        options={epidemiologicalWeeksOptionsList}
        onChange={(e) =>
          handleFiltersChange(
            filterName,
            !e
              ? {
                  label: "Selecione",
                  value: "",
                }
              : e
          )
        }
        {...props}
      />
    </div>
  );
};
