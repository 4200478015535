import React from "react";

export const DateFilter = ({
  filterName,
  handleFiltersChange,
  id,
  value,
  ...props
}) => {
  return (
    <input
      type="date"
      name={id}
      id={id}
      className="form-control"
      value={value}
      onChange={({ target }) => handleFiltersChange(filterName, target.value)}
      {...props}
    />
  );
};
