import React from "react";
import { Select } from "../Wrappers/SelectAll";
import { selectComponentStyles } from "../../services/utils/globalFunctions";

const UsersFilter = ({
  filterName,
  handleFiltersChange,
  options,
  value,
  ...props
}) => {
  return (
    <div>
      <Select
        styles={selectComponentStyles}
        isMulti={true}
        placeholder={"Selecione"}
        isClearable={true}
        options={options}
        value={value}
        onChange={(e) =>
          handleFiltersChange(filterName, !e || e.length === 0 ? [] : e)
        }
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        {...props}
      />
    </div>
  );
};

export default UsersFilter;