import React from "react";

import { ToastContainer } from "react-toastify";
import { Container } from "reactstrap";
import TrapsPageFilters from "./Filters/TrapsPageFilters";
import TrapsOverviewCardsWrapper from "./Cards/TrapsOverviewCardsWrapper";
import TrapsSituationByColorCardsWrapper from "./Cards/TrapsSituationByColorCardsWrapper";
import TrapsPageTable from "./Tables/TrapsPageTable";
import TrapsPageMapWrapper from "./Maps/TrapsPageMapWrapper";
import { generateTrapGroupsPointsList } from "../../../services/utils/globalFunctions";
import useTrapTypesList from "../../../hooks/useTrapTypesList";
import useTrapsList from "../../../hooks/useTrapsList";
import { HistogramTrapsSituationsByColorsGraphics } from "./Graphics/HistogramTrapsSituationsByColorsGraphics";
import { MapContextProvider } from "./Contexts/mapContext";


const useTrapsPage = () => {
  const { trapTypesList } = useTrapTypesList();
  const { trapsList } = useTrapsList();

  const formatedGroupPoints = React.useMemo(() => {
    if (
      !trapsList ||
      trapsList.length === 0 ||
      !trapTypesList ||
      trapTypesList.length === 0
    )
      return [];

    const groupsList = generateTrapGroupsPointsList(trapTypesList, trapsList);

    return groupsList;
  }, [trapsList, trapTypesList]);

  return { formatedGroupPoints, trapsList };
};

const TrapsPage = () => {
  const { formatedGroupPoints, trapsList } = useTrapsPage();

  return (
    <main>
      <ToastContainer />
      <Container fluid className="bg-gradient-info pt-5 pb-2 mb-5">
        <div style={{ paddingTop: "10px !important", color: "#6d6d6d" }}>
          &nbsp;
        </div>
        <TrapsPageFilters />
      </Container>
      <Container fluid className="pb-2 mt-2">
        <TrapsOverviewCardsWrapper />
        <TrapsSituationByColorCardsWrapper />
        <MapContextProvider>
          <TrapsPageMapWrapper groupPointsList={formatedGroupPoints} />
          <HistogramTrapsSituationsByColorsGraphics trapsList={trapsList} />
          <TrapsPageTable />
        </MapContextProvider>
      </Container>
    </main>
  );
};

export default TrapsPage;