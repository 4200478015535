import { useContextSelector } from "use-context-selector";
import { OrganizationContext } from "../contexts/organizationContext";

const usePolygonsColorsPreferences = () => {
  const polygonsColorsPreferences = useContextSelector(
    OrganizationContext,
    (context) => context.polygonsColorsPreferences
  );
  const handleAddPolygonPreferenceColor = useContextSelector(
    OrganizationContext,
    (context) => context.handleAddPolygonPreferenceColor
  );
  const handleDeletePolygonPreferenceColor = useContextSelector(
    OrganizationContext,
    (context) => context.handleDeletePolygonPreferenceColor
  );

  return {
    polygonsColorsPreferences,
    handleAddPolygonPreferenceColor,
    handleDeletePolygonPreferenceColor,
  };
};

export default usePolygonsColorsPreferences;
