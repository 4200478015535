
import { getUserData } from "../auth";
import { getDemandIdByOrganizationId } from "./EntomologicVigilance";

const getTrapTypeName = {
  ADI: "armadilhaDisseminadoraInseticida",
  OVITRAMPA: "armadilhaOvos",
  "BG-GAT": "armadilhaMosquitoAdulto2",
};

export const getTrapToPostObject = (
  tableRow,
  headerInputs,
  trapInformations
) => {
  const organizationId = getUserData("organizationId");

  return {
    realty: {
      number: tableRow.addressNumber,
      subpremise: tableRow.address,
      activityType: tableRow.propertyType,
      location: null,
    },
    trapTypeName: getTrapTypeName[trapInformations.trapName],
    whoInstalledId: headerInputs.teamsAndEmployees[0].id,
    organizationId,
    number: tableRow.trapNumber,
    latitude: trapInformations.latitude,
    longitude: trapInformations.longitude,
  };
};

export const getTrapHistoryObject = (
  tableRow,
  headerInputs,
  trapInformations,
  trapId,
  entomologicVigilanceId
) => {
  const organizationId = getUserData("organizationId");
  const userId = getUserData("userId");

  return {
    trapNumber: tableRow.trapNumber,
    organizationId,
    DemandId: !getDemandIdByOrganizationId[organizationId] ? "0000000-0000-0000-0000-000000000000" : getDemandIdByOrganizationId[organizationId],
    TrapId: trapId,
    UserId: headerInputs.teamsAndEmployees[0].id,
    StatusDate: headerInputs.activityDate,
    TrapWasInstalled: trapInformations.install,
    TrapWasRead: trapInformations.maintenance,
    TrapWasRemoved: trapInformations.uninstall,
    Latitude: trapInformations.latitude,
    Longitude: trapInformations.longitude,
    ManuallyChanged: false,
    VisitId: entomologicVigilanceId,
    TypistId: userId,
    trapNumberHistory: !tableRow.changedLabelNumber || tableRow.newLabelNumber === ""
      ? null
      : {
          newNumber: tableRow.newLabelNumber,
          previousNumber: tableRow.trapNumber,
          dateOfChange: headerInputs.activityDate,
        },
  };
};
