import { useContextSelector } from "use-context-selector";
import { OrganizationContext } from "../contexts/organizationContext";

const useProjectsList = () => {
  const projectsList = useContextSelector(
    OrganizationContext,
    (context) => context.projectsList
  );
  const setProjectsList = useContextSelector(
    OrganizationContext,
    (context) => context.setProjectsList
  );

  return { projectsList, setProjectsList };
};

export default useProjectsList;
