export const GOOGLE_MAPS_API_KEY = "AIzaSyAAQ4VV9KNhjHl1B8pEihrN5axtF_hhKl8";

export const PROPERTY_TYPES_OPTIONS = [
    { label: "Residência (R)", value: "Residência" },
    { label: "Comércio (C)", value: "Comércio" },
    { label: "Terreno Baldio (TB)", value: "Terreno Baldio" },
    { label: "Ponto Estratégico (PE)", value: "Ponto Estratégico" },
    { label: "Próprio Público (PP)", value: "Próprio Público" },
    { label: "Imóvel Especial (IE)", value: "Imóvel Especial" },
    { label: "Via Pública (VP)", value: "Via Pública" },
    { label: "Unidade de Saúde (US)", value: "Unidade de Saúde" },
    { label: "Escola (E)", value: "Escola" },
    { label: "Condomínios (CD)", value: "Condomínios" },
    { label: "Clube (CB)", value: "Clube" },
    { label: "Outros (O)", value: "outros" },
  ];