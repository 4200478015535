import React from "react";

import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import LoadingSpin from "react-loading-spin";
import { toast } from "react-toastify";
import useUserPreferences from "../../../../hooks/useUserPreferences";
import { selectComponentStyles } from "../../../../services/utils/globalFunctions";
import useEmployeesList from "../../../../hooks/useEmployeesList";
import useDemandsList from "../../../../hooks/useDemandsList";
import useTeamsList from "../../../../hooks/useTeamsList";
import useTerritorializationsList from "../../../../hooks/useTerritorializationsList";
import useTrapTypesList from "../../../../hooks/useTrapTypesList";

const usePreferencesModalWrapper = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { employeesList } = useEmployeesList();
  const { demandsList } = useDemandsList();
  const { teamsList } = useTeamsList();
  const { territorializationsList } = useTerritorializationsList();
  const { trapTypesList } = useTrapTypesList();

  const toggleModal = () => {
    setIsOpen((previousValue) => !previousValue);
  };

  return {
    employeesList,
    demandsList,
    isOpen,
    teamsList,
    territorializationsList,
    toggleModal,
    trapTypesList,
  };
};

export const PreferencesModalWrapper = () => {
  const {
    demandsList,
    employeesList,
    isOpen,
    teamsList,
    territorializationsList,
    trapTypesList,
    toggleModal,
  } = usePreferencesModalWrapper();

  return (
    <>
      <PreferencesModal
        employeesList={employeesList}
        demandsList={demandsList}
        isOpen={isOpen}
        toggleModal={toggleModal}
        teamsList={teamsList}
        territorializationsList={territorializationsList}
        trapTypesList={trapTypesList}
      />
      <Button
        onClick={toggleModal}
        style={{ width: "150px", margin: "0px" }}
      >
        <i className="fa fa-cog"></i> Preferências
      </Button>
    </>
  );
};

const usePreferencesModal = () => {
  const [filters, setFilters] = React.useState({
    demand: {
      label: "Todas",
      value: "",
    },
    employee: [],
    team: {
      label: "Todas",
      members: [],
      value: "",
    },
    territorialization: [],
    trapType: {
      label: "Selecione uma opção",
      trapTypeName: "",
      value: "",
    },
  });
  const [isOpen, setIsOpen] = React.useState(true);
  const { preferences, addPreferences } = useUserPreferences();

  React.useEffect(() => {
    if (!preferences || !("trapsPage" in preferences)) return;

    setFilters(preferences.trapsPage);
  }, [preferences]);

  const handlePreferencesChange = (filterName, newValue) => {
    setFilters((previousValue) => ({
      ...previousValue,
      [filterName]: newValue,
    }));
  };

  const handleSavePreferences = () => {
    const addedPreference = addPreferences("trapsPage", filters);

    if (!addedPreference) {
      toast.error(
        "Não foi possível salvar as preferências. Tente novamente mais tarde. Caso o erro persista, entre em contato com a nossa equipe."
      );

      return;
    }

    toast.success("Preferências salvas com sucesso!");
  };

  return {
    addPreferences,
    filters,
    handlePreferencesChange,
    handleSavePreferences,
    isOpen,
    preferences,
    setFilters,
    setIsOpen,
  };
};

const PreferencesModal = ({
  employeesList,
  demandsList,
  isOpen,
  teamsList,
  territorializationsList,
  trapTypesList,
  toggleModal,
}) => {
  const { filters, handlePreferencesChange, handleSavePreferences } =
    usePreferencesModal();

  const styles = {
    listItem: {
      display: "grid",
      gridTemplateColumns: "1fr auto 1fr",
      gap: "1rem",
      alignItems: "center",
      marginBottom: "1rem",
    },
  };

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader className="pb-0">
        <span className="h2">Preferências</span>
        <p style={{ fontSize: "14px" }}>
          Defina os valores para os filtros de sua prefêrencia. A tela sempre
          abrirá com eles selecionados por padrão.
        </p>
      </ModalHeader>
      <ModalBody className="pt-0 pb-0">
        <Row className="mb-2">
          <Col>
            <span style={{ fontSize: "14px" }}>Filtros</span>
          </Col>
          <Col>
            <span
              style={{
                fontSize: "14px",
                paddingLeft: ".5rem",
              }}
            >
              Valores
            </span>
          </Col>
        </Row>
        <ul style={{ listStyle: "none", padding: "0px" }}>
          <li style={styles.listItem}>
            <div className="form-control">
              <span style={{ color: "black" }}>
                <i className="fa fa-filter"></i> Demanda
              </span>
            </div>
            {!demandsList || demandsList.length === 0 ? (
              <LoadingSpin size={18} color={"#ffffff"} />
            ) : (
              <i className="fa fa-arrow-right"></i>
            )}
            <Select
              styles={selectComponentStyles}
              placeholder={"Selecione uma opção"}
              options={[
                {
                  label: "Todas",
                  value: "",
                },
                ...demandsList,
              ]}
              value={filters.demand}
              onChange={(e) => handlePreferencesChange("demand", e)}
              isDisabled={demandsList.length === 0}
            />
          </li>
          <li style={styles.listItem}>
            <div className="form-control">
              <span style={{ color: "black" }}>
                <i className="fa fa-filter"></i> Equipe
              </span>
            </div>
            {!teamsList || teamsList.length === 0 ? (
              <LoadingSpin size={18} color={"#ffffff"} />
            ) : (
              <i className="fa fa-arrow-right"></i>
            )}
            <Select
              styles={selectComponentStyles}
              placeholder={"Selecione uma opção"}
              options={[
                {
                  label: "Todas",
                  members: [],
                  value: "",
                },
                ...teamsList,
              ]}
              value={filters.team}
              onChange={(e) => handlePreferencesChange("team", e)}
              isDisabled={teamsList.length === 0}
            />
          </li>
          <li style={styles.listItem}>
            <div className="form-control">
              <span style={{ color: "black" }}>
                <i className="fa fa-filter"></i> Funcionário
              </span>
            </div>
            {!employeesList || employeesList.length === 0 ? (
              <LoadingSpin size={18} color={"#ffffff"} />
            ) : (
              <i className="fa fa-arrow-right"></i>
            )}
            <Select
              styles={selectComponentStyles}
              placeholder={"Selecione uma ou mais opções"}
              isMulti={true}
              isClearable={true}
              blurInputOnSelect={false}
              closeMenuOnSelect={false}
              options={employeesList}
              value={filters.employee}
              onChange={(e) =>
                handlePreferencesChange(
                  "employee",
                  !e || e.length === 0 ? [] : e
                )
              }
              isDisabled={employeesList.length === 0}
            />
          </li>
          <li style={styles.listItem}>
            <div className="form-control">
              <span style={{ color: "black" }}>
                <i className="fa fa-filter"></i> Tipo de armadilha
              </span>
            </div>
            {!trapTypesList || trapTypesList.length === 0 ? (
              <LoadingSpin size={18} color={"#ffffff"} />
            ) : (
              <i className="fa fa-arrow-right"></i>
            )}
            <Select
              styles={selectComponentStyles}
              placeholder={"Selecione uma opção"}
              options={trapTypesList}
              value={filters.trapType}
              onChange={(e) => handlePreferencesChange("trapType", e)}
              isDisabled={trapTypesList.length === 0}
            />
          </li>
          <li style={styles.listItem}>
            <div className="form-control">
              <span style={{ color: "black" }}>
                <i className="fa fa-filter"></i> Territorialização
              </span>
            </div>
            {!territorializationsList ||
            territorializationsList.length === 0 ? (
              <LoadingSpin size={18} color={"#ffffff"} />
            ) : (
              <i className="fa fa-arrow-right"></i>
            )}
            <Select
              styles={selectComponentStyles}
              placeholder={"Selecione uma ou mais opções"}
              isMulti={true}
              isClearable={true}
              blurInputOnSelect={false}
              closeMenuOnSelect={false}
              options={territorializationsList}
              value={filters.territorialization}
              onChange={(e) =>
                handlePreferencesChange(
                  "territorialization",
                  !e || e.length === 0 ? [] : e
                )
              }
              isDisabled={territorializationsList.length === 0}
            />
          </li>
        </ul>
      </ModalBody>
      <ModalFooter style={{ borderTop: "1px solid #f0f1f3" }}>
        <Button
          style={{ width: "130px" }}
          color="secondary"
          onClick={toggleModal}
        >
          Fechar
        </Button>
        <Button
          style={{ width: "170px" }}
          color="primary"
          onClick={handleSavePreferences}
        >
          Salvar preferências
        </Button>
      </ModalFooter>
    </Modal>
  );
};