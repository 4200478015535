import React from "react";

import { Col, Row } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import { getUserData } from "../../../../services/utils/auth";

const TrapsPageTableFilters = ({
  employeesList,
  filters,
  handleFiltersChange,
  trapTypesList,
}) => {
  const [organizationId, setOrganizationId] = React.useState();

  React.useEffect(() => {
    var organizationId = getUserData("organizationId");

    setOrganizationId(organizationId);
  }, [])

  const selectComponentStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  const trapColorStatusOptions = [
    {
      label: "Todos",
      value: "all",
    },
    {
      label: "Verde",
      value: "green",
    },
    {
      label: "Amarelo",
      value: "yellow",
    },
    {
      label: "Vermelho",
      value: "red",
    },
  ];

  const trapStatusOptions = [
    {
      label: "Todas",
      value: "all",
    },
    {
      label: "Instalada",
      value: "instaled",
    },
    {
      label: "Desinstalada",
      value: "removed",
    },
    {
      label: "Sem coordenadas",
      value: "notGeoreferenced",
    },
    {
      label: "Georreferenciada por endereço",
      value: "georeferencedByAddress",
    },
  ];

  return (
    <Row>
      <Col xl="4" className="mb-3">
        <span style={{ fontWeight: "bold" }}>Número</span>
        <input
          type="text"
          className="form-control"
          value={filters.number}
          onChange={({ target }) => handleFiltersChange("number", target.value)}
          placeholder="Digite o número da armadilha"
        />
      </Col>
      <Col xl="4" className="mb-3">
        <span style={{ fontWeight: "bold" }}>Status por cor</span>
        <Select
          styles={selectComponentStyles}
          placeholder={"Selecione"}
          isClearable={false}
          options={trapColorStatusOptions}
          value={filters.statusByColor}
          onChange={(e) => handleFiltersChange("statusByColor", e)}
        />
      </Col>
      <Col xl="4" className="mb-3">
        <span style={{ fontWeight: "bold" }}>Tipo</span>
        <Select
          styles={selectComponentStyles}
          placeholder={"Selecione"}
          isClearable={false}
          options={[
            {
              label: "Todos",
              value: "all",
            },
            ...trapTypesList,
          ]}
          value={filters.type}
          onChange={(e) => handleFiltersChange("type", e)}
        />
      </Col>      
      <Col xl="4" className="mb-3">
        <span style={{ fontWeight: "bold" }}>Quem instalou</span>
        <Select
          styles={selectComponentStyles}
          placeholder={"Selecione"}
          isClearable={false}
          options={[
            {
              label: "Todos",
              value: "all",
            },
            ...employeesList,
          ]}
          value={filters.installerId}
          onChange={(e) => handleFiltersChange("installerId", e)}
        />
      </Col>
      <Col xl="4" className="mb-3">
        <span style={{ fontWeight: "bold" }}>Quem leu</span>
        <Select
          styles={selectComponentStyles}
          placeholder={"Selecione"}
          isClearable={false}
          options={[
            {
              label: "Todos",
              value: "all",
            },
            ...employeesList,
          ]}
          value={filters.readerId}
          onChange={(e) => handleFiltersChange("readerId", e)}
        />
      </Col>
      {/* <Col xl="4" className="mb-3">
        <span style={{ fontWeight: "bold" }}>Quem digitou</span>
        <Select
          styles={selectComponentStyles}
          placeholder={"Selecione"}
          isClearable={false}
          options={[
            {
              label: "Todos",
              value: "all",
            },
            ...employeesList,
          ]}
          value={filters.typistId}
          onChange={(e) => handleFiltersChange("typistId", e)}
          isDisabled={true}
        />
      </Col> */}
      <Col xl="4" className="mb-3">
        <span style={{ fontWeight: "bold" }}>Rua</span>
        <input
          type="text"
          className="form-control"
          value={filters.address}
          onChange={({ target }) =>
            handleFiltersChange("address", target.value)
          }
          placeholder="Digite o nome da rua"
        />
      </Col>
      {/* <Col xl="4" className="mb-3">
        <span style={{ fontWeight: "bold" }}>Data de digitação</span>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <div style={{ width: "45%" }}>
              <input
                type="date"
                value={filters.beginTypingDate}
                onChange={({ target }) =>
                  handleFiltersChange("beginTypingDate", target.value)
                }
                className="form-control"
                name="beginTypingDate"
                id="beginTypingDate"
              />
            </div>
            <div
              className="h4 text-white"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold", color: "#525f7f" }}>Até</span>
            </div>
            <div style={{ width: "45%" }}>
              <input
                type="date"
                value={filters.endTypingDate}
                onChange={({ target }) =>
                  handleFiltersChange("endTypingDate", target.value)
                }
                className="form-control"
                name="endTypingDate"
                id="endTypingDate"
              />
            </div>
          </div>
        </div>
      </Col> */}
      <Col xl="4" className="mb-3">
        <span style={{ fontWeight: "bold" }}>Exibir armadilhas</span>
        <Select
          styles={selectComponentStyles}
          placeholder={"Selecione"}
          isClearable={false}
          options={trapStatusOptions}
          value={filters.status}
          onChange={(e) => handleFiltersChange("status", e)}
        />
      </Col>
      <Col xl="4" className="mb-3">
        <span style={{ fontWeight: "bold" }}>Data de instalação</span>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <div style={{ width: "45%" }}>
              <input
                type="date"
                value={filters.beginInstallDate}
                onChange={({ target }) =>
                  handleFiltersChange("beginInstallDate", target.value)
                }
                className="form-control"
                name="beginInstallDate"
                id="beginInstallDate"
              />
            </div>
            <div
              className="h4 text-white"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold", color: "#525f7f" }}>Até</span>
            </div>
            <div style={{ width: "45%" }}>
              <input
                type="date"
                value={filters.endInstallDate}
                onChange={({ target }) =>
                  handleFiltersChange("endInstallDate", target.value)
                }
                className="form-control"
                name="endInstallDate"
                id="endInstallDate"
              />
            </div>
          </div>
        </div>
      </Col>
      <Col xl="4" className="mb-3">
        <span style={{ fontWeight: "bold" }}>Data de manutenção</span>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <div style={{ width: "45%" }}>
              <input
                type="date"
                value={filters.beginReadDate}
                onChange={({ target }) =>
                  handleFiltersChange("beginReadDate", target.value)
                }
                className="form-control"
                name="beginReadDate"
                id="beginReadDate"
              />
            </div>
            <div
              className="h4 text-white"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold", color: "#525f7f" }}>Até</span>
            </div>
            <div style={{ width: "45%" }}>
              <input
                type="date"
                value={filters.endReadDate}
                onChange={({ target }) =>
                  handleFiltersChange("endReadDate", target.value)
                }
                className="form-control"
                name="endReadDate"
                id="endReadDate"
              />
            </div>
          </div>
        </div>
      </Col>
      <Col xl="4" className="mb-3">
        <span style={{ fontWeight: "bold" }}>Data de desinstalação</span>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <div style={{ width: "45%" }}>
              <input
                type="date"
                value={filters.beginUninstallDate}
                onChange={({ target }) =>
                  handleFiltersChange("beginUninstallDate", target.value)
                }
                className="form-control"
                name="beginUninstallDate"
                id="beginUninstallDate"
              />
            </div>
            <div
              className="h4 text-white"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold", color: "#525f7f" }}>Até</span>
            </div>
            <div style={{ width: "45%" }}>
              <input
                type="date"
                value={filters.endUninstallDate}
                onChange={({ target }) =>
                  handleFiltersChange("endUninstallDate", target.value)
                }
                className="form-control"
                name="endUninstallDate"
                id="endUninstallDate"
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default TrapsPageTableFilters;