import { useContextSelector } from "use-context-selector";
import { TrapsPageContext } from "../Contexts/trapsPageContext";

const useTrapsSituationsByColors = () => {
  const trapsSituationsByColors = useContextSelector(
    TrapsPageContext,
    (context) => context.trapsSituationsByColors
  );
  const setTrapsSituationsByColors = useContextSelector(
    TrapsPageContext,
    (context) => context.setTrapsSituationsByColors
  );

  return {
    trapsSituationsByColors,
    setTrapsSituationsByColors,
  };
};

export default useTrapsSituationsByColors;
