import React from "react";

import { Card, CardBody, CardText, Col, Row } from "reactstrap";
import { getTrapRange } from "../../../../constants/RangeTrapConstant";
import {
  GREEN_TRAP_STATUS,
  INACTIVE_TRAP_STATUS,
  RED_TRAP_STATUS,
  YELLOW_TRAP_STATUS,
} from "../../../../services/utils/globalFunctions";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import useTrapsList from "../../../../hooks/useTrapsList";
import useLoading from "../Hooks/useLoading";
import CardLoadingSkeleton from "../../../../components/ui/Loading/CardLoadingSkeleton";
import useTrapsSituationsByColors from "../Hooks/useTrapsSituationsByColors";
import { getTodayEpidemiologicalWeek } from "../../../../services/utils/todayEpidemiologicalWeek";

const styles = {
  cardBody: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  cardText: { fontWeight: "bold", marginBottom: "0px" },
};

const useTrapsSituationByColorCardsWrapper = () => {
  const [trapTypeRange, setTrapTypeRange] = React.useState({
    quantityRange: 3,
    data: {
      middle: -1,
      end: -1,
    },
  });
  const { appliedFilters } = useAppliedFilters();
  const { trapsList } = useTrapsList();
  const { isTrapsListLoading } = useLoading();
  const { setTrapsSituationsByColors, trapsSituationsByColors } =
    useTrapsSituationsByColors();

  React.useEffect(() => {
    if (
      !trapsList ||
      trapsList.length === 0 ||
      !appliedFilters ||
      !appliedFilters.trapType ||
      appliedFilters.trapType.value === ""
    )
      return;

    const situationsByColors = {
      green: 0,
      yellow: 0,
      red: 0,
      white: 0,
    };

    for (let i = 0; i < trapsList.length; i++) {
      const {
        status,
        colorStatus,
        trapType: { name },
      } = trapsList[i];

      if (
        status === INACTIVE_TRAP_STATUS ||
        name !== appliedFilters.trapType.trapTypeName
      )
        continue;

      if (colorStatus === GREEN_TRAP_STATUS) {
        situationsByColors.green += 1;
        continue;
      }

      if (colorStatus === YELLOW_TRAP_STATUS) {
        situationsByColors.yellow += 1;
        continue;
      }

      if (colorStatus === RED_TRAP_STATUS) {
        situationsByColors.red += 1;
        continue;
      }
    }

    const trapRange = getTrapRange(appliedFilters.trapType.trapTypeName);

    setTrapsSituationsByColors({
      ...situationsByColors,
      range: trapRange,
      trapTypeName: appliedFilters.trapType.trapTypeName,
    });
    setTrapTypeRange(trapRange);
  }, [trapsList, appliedFilters]);

  return {
    appliedFilters,
    isTrapsListLoading,
    trapsSituationsByColors,
    trapTypeRange,
  };
};

const TrapsSituationByColorCardsWrapper = () => {
  const {
    appliedFilters,
    isTrapsListLoading,
    trapTypeRange,
    trapsSituationsByColors,
  } = useTrapsSituationByColorCardsWrapper();

  if (
    isTrapsListLoading ||
    (trapsSituationsByColors.green === 0 &&
      trapsSituationsByColors.yellow === 0 &&
      trapsSituationsByColors.red === 0 &&
      trapsSituationsByColors.white === 0)
  )
    return (
      <Row className="mb-4" style={{ justifyContent: "center" }}>
        {trapTypeRange && trapTypeRange.quantityRange === 2 ? (
          <>
            <Col className="col-md-3 visit-responsive-filter">
              <CardLoadingSkeleton middleBarWidth="250px" />
            </Col>
            <Col className="col-md-3 visit-responsive-filter">
              <CardLoadingSkeleton middleBarWidth="250px" />
            </Col>
          </>
        ) : trapTypeRange && trapTypeRange.quantityRange === 3 ? (
          <>
            <Col className="col-md-3 visit-responsive-filter">
              <CardLoadingSkeleton middleBarWidth="250px" />
            </Col>
            <Col className="col-md-3 visit-responsive-filter">
              <CardLoadingSkeleton middleBarWidth="250px" />
            </Col>
            <Col className="col-md-3 visit-responsive-filter">
              <CardLoadingSkeleton middleBarWidth="250px" />
            </Col>
          </>
        ) : null}
      </Row>
    );

  return (
    <>
      <Row className="mb-4" style={{ justifyContent: "center" }}>
        {trapTypeRange && trapTypeRange.quantityRange === 2 ? (
          <>
            {appliedFilters.colorStatuses.find(
              ({ value }) => value === GREEN_TRAP_STATUS
            ) && (
              <Col className="col-md-3 visit-responsive-filter">
                <GreenStatusCard
                  trapTypeRange={trapTypeRange}
                  value={trapsSituationsByColors.green}
                />
              </Col>
            )}
            {appliedFilters.colorStatuses.find(
              ({ value }) => value === RED_TRAP_STATUS
            ) && (
              <Col className="col-md-3 visit-responsive-filter">
                <RedStatusCard
                  trapTypeRange={trapTypeRange}
                  value={trapsSituationsByColors.red}
                />
              </Col>
            )}
          </>
        ) : trapTypeRange && trapTypeRange.quantityRange === 3 ? (
          <>
            {appliedFilters.colorStatuses.find(
              ({ value }) => value === GREEN_TRAP_STATUS
            ) && (
              <Col className="col-md-3 visit-responsive-filter">
                <GreenStatusCard
                  trapTypeRange={trapTypeRange}
                  value={trapsSituationsByColors.green}
                />
              </Col>
            )}
            {appliedFilters.colorStatuses.find(
              ({ value }) => value === YELLOW_TRAP_STATUS
            ) && (
              <Col className="col-md-3 visit-responsive-filter">
                <YellowStatusCard
                  trapTypeRange={trapTypeRange}
                  value={trapsSituationsByColors.yellow}
                />
              </Col>
            )}
            {appliedFilters.colorStatuses.find(
              ({ value }) => value === RED_TRAP_STATUS
            ) && (
              <Col className="col-md-3 visit-responsive-filter">
                <RedStatusCard
                  trapTypeRange={trapTypeRange}
                  value={trapsSituationsByColors.red}
                />
              </Col>
            )}
          </>
        ) : null}
      </Row>
      <span className="h4 mb-4" style={{ textAlign: "center", display: "block" }}>
        Semana epidemiológica atual: {getTodayEpidemiologicalWeek()}
      </span>
    </>
  );
};

const GreenStatusCard = ({ trapTypeRange, value }) => {
  return (
    <Card className="card-stats" style={{ height: "8rem" }}>
      <CardBody style={styles.cardBody}>
        <CardText style={styles.cardText}>{value}</CardText>
        <CardText style={{ fontSize: ".9rem" }}>
          <span>
            Instalação ou manutenção recém realizada
            <br />
            (até{" "}
            {trapTypeRange && trapTypeRange.quantityRange === 2
              ? trapTypeRange.data.end
              : trapTypeRange.data.middle}{" "}
            dias)
            <span
              className="badge badge-success"
              style={{
                width: "50px",
                marginLeft: "5px",
                backgroundColor: "#76ea79",
              }}
            >
              &nbsp;
            </span>
          </span>
        </CardText>
      </CardBody>
    </Card>
  );
};

const YellowStatusCard = ({ trapTypeRange, value }) => {
  return (
    <Card className="card-stats" style={{ height: "8rem" }}>
      <CardBody style={styles.cardBody}>
        <CardText style={styles.cardText}>{value}</CardText>
        <CardText style={{ fontSize: ".9rem" }}>
          <span>
            Atenção para próxima manutenção
            <br />({trapTypeRange && trapTypeRange.data.middle + 1} a{" "}
            {trapTypeRange && trapTypeRange.data.end} dias)
            <span
              className="badge badge-success"
              style={{
                width: "50px",
                marginLeft: "5px",
                backgroundColor: "#fff200",
              }}
            >
              &nbsp;
            </span>
          </span>
        </CardText>
      </CardBody>
    </Card>
  );
};

const RedStatusCard = ({ trapTypeRange, value }) => {
  return (
    <Card className="card-stats" style={{ height: "8rem" }}>
      <CardBody style={styles.cardBody}>
        <CardText style={styles.cardText}>{value}</CardText>
        <CardText style={{ fontSize: ".9rem" }}>
          <span>
            Ultrapassou o prazo de manutenção
            <br />
            (acima de {trapTypeRange && trapTypeRange.data.end} dias)
            <span
              className="badge badge-danger"
              style={{
                width: "50px",
                marginLeft: "5px",
                backgroundColor: "#ff0000",
              }}
            >
              &nbsp;
            </span>
          </span>
        </CardText>
      </CardBody>
    </Card>
  );
};

export default TrapsSituationByColorCardsWrapper;