import React from "react";

import { createContext } from "use-context-selector";
import { getUserData } from "../services/utils/auth";

const OrganizationContext = createContext(null);

const useOrganizationContext = () => {
  const context = React.useContext(OrganizationContext);

  if (!context)
    throw new Error("useContext de Organization deve estar dentro do Provider");

  return context;
};

const OrganizationContextProvider = ({ children }) => {
  const [demandsList, setDemandsList] = React.useState([]);
  const [employeesList, setEmployeesList] = React.useState([]);
  const [teamsList, setTeamsList] = React.useState([]);
  const [territorializationsList, setTerritorializationsList] = React.useState(
    []
  );
  const [trapsList, setTrapsList] = React.useState([]);
  const [trapTypesList, setTrapTypesList] = React.useState([]);
  const [typeLayersList, setTypeLayersList] = React.useState([]);
  const [projectsList, setProjectsList] = React.useState([]);
  const [polygonsColorsPreferences, setPolygonsColorsPreferences] =
    React.useState([]);

  React.useEffect(() => {
    const polygonsColorsPreferences = localStorage.getItem(
      "polygonsColorsPreferences"
    )
      ? JSON.parse(localStorage.getItem("polygonsColorsPreferences"))
      : null;

    if (!polygonsColorsPreferences) {
      const emptyPreferences = [];

      setPolygonsColorsPreferences(emptyPreferences);
      localStorage.setItem(
        "polygonsColorsPreferences",
        JSON.stringify(emptyPreferences)
      );

      return;
    }

    setPolygonsColorsPreferences(polygonsColorsPreferences);
  }, []);

  const handleAddPolygonPreferenceColor = React.useCallback((preference) => {
    const NOT_FOUND = -1;

    const polygonsColorsPreferences = localStorage.getItem(
      "polygonsColorsPreferences"
    )
      ? JSON.parse(localStorage.getItem("polygonsColorsPreferences"))
      : null;

    const previousPreferenceIndex = checkIfPreferenceAlreadyExist(preference);

    if (previousPreferenceIndex === NOT_FOUND) {
      const newPreferences = [...polygonsColorsPreferences, preference];

      setPolygonsColorsPreferences(newPreferences);

      localStorage.setItem(
        "polygonsColorsPreferences",
        JSON.stringify(newPreferences)
      );

      return;
    }

    const newPreferences = [...polygonsColorsPreferences];
    newPreferences[previousPreferenceIndex] = preference;

    setPolygonsColorsPreferences(newPreferences);
    localStorage.setItem(
      "polygonsColorsPreferences",
      JSON.stringify(newPreferences)
    );
  }, []);

  const handleDeletePolygonPreferenceColor = React.useCallback((preference) => {
    const NOT_FOUND = -1;

    const polygonsColorsPreferences = localStorage.getItem(
      "polygonsColorsPreferences"
    )
      ? JSON.parse(localStorage.getItem("polygonsColorsPreferences"))
      : null;

    const previousPreferenceIndex = checkIfPreferenceAlreadyExist(preference);

    if (previousPreferenceIndex === NOT_FOUND)
      return;

    const newPreferences = [...polygonsColorsPreferences];
    newPreferences.splice(previousPreferenceIndex, 1);

    setPolygonsColorsPreferences(newPreferences);
    localStorage.setItem(
      "polygonsColorsPreferences",
      JSON.stringify(newPreferences)
    );
  }, []);

  const checkIfPreferenceAlreadyExist = React.useCallback((preference) => {
    const organizationId = getUserData("organizationId");

    const polygonsColorsPreferences = localStorage.getItem(
      "polygonsColorsPreferences"
    )
      ? JSON.parse(localStorage.getItem("polygonsColorsPreferences"))
      : null;

    if (!polygonsColorsPreferences) {
      return false;
    }

    if (preference.entity === "polygon")
      return polygonsColorsPreferences.findIndex(
        (colorPreference) =>
          colorPreference.polygonId === preference.polygonId &&
          colorPreference.organizationId === organizationId
      );

    if (preference.entity === "typeLayer")
      return polygonsColorsPreferences.findIndex(
        (colorPreference) =>
          colorPreference.typeLayerId === preference.typeLayerId &&
          colorPreference.organizationId === organizationId
      );
  }, []);

  return (
    <OrganizationContext.Provider
      value={{
        demandsList,
        employeesList,
        teamsList,
        territorializationsList,
        trapsList,
        trapTypesList,
        typeLayersList,
        projectsList,
        polygonsColorsPreferences,
        handleAddPolygonPreferenceColor,
        handleDeletePolygonPreferenceColor,
        setDemandsList,
        setEmployeesList,
        setTeamsList,
        setTerritorializationsList,
        setTrapsList,
        setTrapTypesList,
        setTypeLayersList,
        setProjectsList,
        setPolygonsColorsPreferences,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export {
  OrganizationContextProvider,
  useOrganizationContext,
  OrganizationContext,
};