import React from "react";

import { Select } from "../Wrappers/SelectAll";
import { selectComponentStyles } from "../../services/utils/globalFunctions";

const TerritorializationsFilter = ({
  handleFiltersChange,
  territorializationsList,
  value,
  ...props
}) => {
  return (
    <div>
      <Select
        styles={selectComponentStyles}
        placeholder={"Selecione"}
        isClearable={true}
        options={territorializationsList}
        value={value}
        isMulti={true}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        onChange={(e) =>
          handleFiltersChange(
            "territorializations",
            !e || e.length === 0 ? [] : e
          )
        }
        {...props}
      />
    </div>
  );
};

export default TerritorializationsFilter;